
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, mainStore } from './Redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom';
// import { SocketProvider } from "./socket/socket"
// import './wydr'
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(

  <Provider store={mainStore}>
    {/* <PersistGate loading={null} persistor={persistor}>
      <SocketProvider> */}
    <Router>
      <App />
    </Router>
    {/* </SocketProvider>
    </PersistGate> */}
  </Provider>
);

reportWebVitals();

