// // @ts-nocheck
// import { createStore, applyMiddleware } from 'redux';
// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { thunk } from 'redux-thunk'; // Correct named import
// import { persistStore, persistReducer } from 'redux-persist';
// import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
// import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';
// import { userDataReducer } from './reducers/auth';
// import { userInfoReducer } from './reducers/userInfoReducer';
// import socketReducer from './reducers/socketReducer';
// import { LOGIN_SUCCESS, LOGOUT } from './constants/authConstant';

// // Combine reducers
// const rootReducer = combineReducers({
//   loginData: userDataReducer,
//   userDetails: userInfoReducer,
//   socket: socketReducer,
// });

// // Configure persistence
// const persistConfig = {
//   key: 'mainPersistReducer',
//   version: 1,
//   storage: createIdbStorage({ name: 'mainApp' }),
//   whitelist: ['loginData', 'userDetails', 'socket'],
// };

// // Persist the reducer
// // const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Sync middleware configuration
// // const syncMiddleware = createStateSyncMiddleware({
// //   whitelist: ['loginData', 'userDetails'],
// //   broadcastChannelOption: {
// //     type: 'localstorage',
// //     channelName: 'mainAppChannel',
// //     persistence: 'permanent',
// //   },
// // });

// const syncMiddleware = createStateSyncMiddleware({
//   whitelist: ['loginData', 'userDetails', 'socket'],
//   broadcastChannelOption: {
//     type: 'localstorage', // Use localStorage for state synchronization between tabs
//     persistence: 'permanent', // Store the state permanently in localStorage
//   },
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);


// // Create the store using Redux Toolkit's configureStore
// // const mainStore = configureStore({
// //   reducer: persistedReducer,
// //   middleware: (getDefaultMiddleware) =>
// //     getDefaultMiddleware({
// //       serializableCheck: false,
// //     }).concat(syncMiddleware),
// //   devTools: process.env.NODE_ENV !== 'production',
// // });

// const mainStore = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, syncMiddleware)));
// initMessageListener(mainStore);

// // const mainStore = configureStore({
// //   reducer: rootReducer, // Use non-persisted reducer for testing
// //   middleware: (getDefaultMiddleware) =>
// //     getDefaultMiddleware({ serializableCheck: false }),
// // });

// // Initialize state sync listener
// // initMessageListener(mainStore);

// // Configure the persistor
// const mainPersistor = persistStore(mainStore);

// export { mainStore, mainPersistor };

import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk'; // Use the named export
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';
import { userDataReducer } from './reducers/auth';
import { userInfoReducer } from './reducers/userInfoReducer';
import socketReducer from './reducers/socketReducer';

// Dynamically import composeWithDevTools to avoid errors if Redux DevTools is unavailable
const composeEnhancers =
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : (f) => f;

// Combine reducers
const rootReducer = combineReducers({
  loginData: userDataReducer,
  userDetails: userInfoReducer,
  socket: socketReducer,
});

// Configure persistence
const persistConfig = {
  key: 'mainPersistReducer',
  version: 1,
  storage: createIdbStorage({ name: 'mainApp' }),
  whitelist: ['loginData', 'userDetails', 'socket'],
};

const syncMiddleware = createStateSyncMiddleware({
  whitelist: ['loginData', 'userDetails', 'socket'],
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Apply middleware
const middlewares = [thunk, syncMiddleware];
const storeEnhancers = composeEnhancers(applyMiddleware(...middlewares));

const mainStore = createStore(persistedReducer, storeEnhancers);
initMessageListener(mainStore);

const mainPersistor = persistStore(mainStore);

export { mainStore, mainPersistor };
