import { SET_SOCKET } from "../actions/socketAction";
import { NEW_APP_LAUNCH } from "../constants/authConstant";
const initialState = {
    socket: null,
    isConnected: false,
};

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SOCKET:
            return {
                ...state,
                socket: action.payload.socket,
                isConnected: action.payload.isConnected,
            };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;
    }
};

export default socketReducer;