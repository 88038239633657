import { FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILED, } from "../constants/userInfoConstant"
import { NEW_APP_LAUNCH } from "../constants/authConstant"
const initialState = {
    userInfo: {},
}

export const userInfoReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_INFO_REQUEST:
            return { ...state }
        case FETCH_USER_INFO_SUCCESS:
            return { ...state, userInfo: payload }
        case FETCH_USER_INFO_FAILED:
            return { ...state, error: payload }

        case NEW_APP_LAUNCH:
            return { ...initialState }

        default: return state
    }
}

