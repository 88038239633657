
// @ts-nocheck
import ROUTES from "../chatRoutes/chatRoutes";
import { Navigate } from "react-router-dom";
export const ProtectedRoutesChat = ({ children, isAccessible }) => {

    const isAuthTokenExist = localStorage.getItem('chatToken');
    if (isAuthTokenExist) {
        if (isAccessible || isAccessible === undefined) {
            return children
        } else {
            return <Navigate to={ROUTES?.CHATGRID} />
        }
    }
    return <Navigate to={ROUTES?.LOGIN} />
}