// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import './Login.css'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { userLoginAction } from '../../Redux/actions/authAction';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginBanner from '../../assests/img/login-banner.jpg';
import GgLogo from '../../assests/img/logo gg.png';
import { useSelector } from 'react-redux';
import ROUTES from '../../chatRoutes/chatRoutes';
import { userInfoAction } from '../../Redux/actions/userInfoAction';
import { COMMON_STRINGS } from '../../Constant/Strings';
import { Notification } from "../../pages/notification/notification";
import { useFetch } from '../../Services/useFetch';
const initialState = {
    username: '',
    password: ''
}


const Login = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    var _ = require('lodash');
    // const { userBidInfo } = useSelector((state) => state.userDetails)
    const { request } = useFetch()
    const [data, setData] = useState(initialState)
    const emailInputRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(true)
    const [errorMessage, setErrorMessage] = useState("");
    // const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const [message, setMessage] = useState('');


    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setPassword(e.target.value);
        setDisableBtn(data.username === '' || data.password === '');
        setErrorMessage('');
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = e => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };
    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);

    const CompanyLogo = async (userChatInfoData) => {
        try {
            const response = await request('/get-company-logo', 'get', null, { 'Content-Type': "multipart/form-data" });
            const responseThumb = await request('/update-company-thumbnail', 'post', {}, { 'Content-Type': "multipart/form-data" });
            if (response?.data?.status == 1 && responseThumb?.data?.status == 1) {
                dispatch(userInfoAction({
                    ...userChatInfoData,
                    thumbnailPath: responseThumb?.data?.data?.thumbnail_file_path,
                    thumbnailName: responseThumb?.data?.data?.thumbnail_file_name,
                    docname: response.data.data?.logo_file_name,
                    docpath: response.data.data?.logo_file_path
                }))
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleSubmit = async (e) => {
        // debugger
        // e.preventDefault()
        setDisableBtn(true)
        if (data.username === '' && data.password === '') {
            notify('please fill all the fields')
        } else if (data.username === '') {
            notify('please fill email')
        } else if (data.password === '') {
            notify('please fill password')
        }
        else {
            setLoader(true)
            const addLoginDetails = new URLSearchParams();
            addLoginDetails.append('username', data?.username)
            addLoginDetails.append('password', data?.password)
            addLoginDetails.append('ip_Adress', localStorage.getItem('ipAddress'))
            // if (localStorage.getItem('setfirebasetoken')) {
            //     addLoginDetails.append('web_push_notification_token', localStorage.getItem('setfirebasetoken'))
            // }
            try {
                // debugger
                const res = await request('/user-login', 'post', addLoginDetails, { 'Content-Type': "application/x-www-form-urlencoded" })
                if (res?.data?.status === "1") {
                    console.log(res.data, "res?.data")
                    dispatch(userLoginAction(res?.data))
                    localStorage.setItem('chatToken', res?.data?.token)
                    const formData = new URLSearchParams();
                    formData.append('user_id', Number(res?.data?.data?.id))
                    try {
                        const response = await request('/user-details-edit', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
                        if (response?.data?.status == 1) {
                            const userChatInfoData = response.data.data;
                            dispatch(userInfoAction({
                                ...userChatInfoData,
                            }));
                            localStorage?.setItem('user_id', userChatInfoData?.id)
                            // CompanyLogo(userChatInfoData)
                            // let navigatingRoute = localStorage.getItem('lastVisited') ? localStorage.getItem('lastVisited') : '/bidgrid/chatgrid';
                            let navigatingRoute = '/chatgrid';
                            navigate(navigatingRoute)
                            notifySuccess(res?.data?.message)
                        }

                        else {
                            notify('You do not have access to log in.')
                        }

                    } catch (error) {
                        console.log(error, 'api error');
                    }
                }
                else {
                    setLoader(false)
                    setErrorMessage(COMMON_STRINGS.INVALID_USER_PASSWORD)
                }
            } catch (error) {
                setLoader(false)
                console.log(error)
            }
        }

        setTimeout(() => {
            setLoader(false)
            setDisableBtn(false)
        }, 2000);
    }


    const handleCheckMsg = () => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (data.username.length === 0) {
            setMessage('');
        }
        if (data.username !== '') {
            if (emailRegex.test(data.username)) {
                setMessage('');
            } else {
                setMessage('Please enter a valid email!');
            }
        }
    }

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        handleCheckMsg()
    }, [data?.username])

    useEffect(() => {
        setDisableBtn(false)
    }, [data.username, data.password])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const checkAuth = () => {
        // debugger;
        let authForLogin = localStorage.getItem('chatToken');
        let adminAuthToken = localStorage.getItem('adminToken');
        const currentPath = window.location.pathname;
        if (authForLogin) {
            if (currentPath === ROUTES?.LOGIN) {
                navigate(ROUTES?.CHATGRID, { replace: true })
                // window.open(ROUTES?.CHATGRID, "_blank")
            }
            if (currentPath === '/') {
                navigate(ROUTES?.CHATGRID, { replace: true })
                // window.open(ROUTES?.CHATGRID, "_blank")
            }
        }
        else if (currentPath.includes('/admin')) {
            if (adminAuthToken) {
                navigate('/admin/dashboard')
            } else {
                navigate('/admin/login')
            }
        }
        else {
            navigate(ROUTES?.LOGIN)
        }
    };

    useEffect(() => {
        checkAuth();
    }, [])

    return (
        <>
            <Notification />
            <div className='login_body'>
                <div className="formWarpper">
                    <div className="loginLogo">
                        <img
                            src={GgLogo}
                            onClick={() => navigate(`/chatgrid`)}
                            width={60}
                            alt=''
                        />
                    </div>
                    <div className="section-title text-center">
                        <h1>Chat Grid Login <span className="one-text"></span></h1>
                    </div>
                    <div className='errormessagename' style={{ color: 'red' }}>
                        {errorMessage}
                    </div>
                    {/* <div className="errormessagename" > {errorMessage} </div> */}

                    <form className="form" method="" action="">
                        <div className="inputGrp">
                            <input onInput={handleChange}
                                ref={emailInputRef}
                                autoComplete="off" onKeyDown={handleKeyDown} value={data?.username} className="form__input" name='username' type="email" placeholder="Enter Email" />
                            <div className='message' style={{ color: 'red' }}>
                                {message}
                            </div>
                            <div className="eye_position">
                                <input
                                    onInput={handleChange}
                                    onKeyDown={handleKeyDown} autoComplete="off" value={data?.password}
                                    className="form__input"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter Password"
                                />
                                {showPassword ? (
                                    <VisibilityIcon className="eye_visible" onClick={togglePasswordVisibility} />
                                ) : (
                                    <VisibilityOffIcon className="eye_visible" onClick={togglePasswordVisibility} />
                                )}
                            </div>
                        </div>
                        <button onClick={() => handleSubmit()} className="mainButton" disabled={disableBtn}>Login</button>
                    </form>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 1000 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


            </div>
        </>
    )
}

export default Login;