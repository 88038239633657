
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyAdl8ONQCwHeUCh-oDI0h6Utf_c5AtUHP8",
    authDomain: "chatweb-14c48.firebaseapp.com",
    projectId: "chatweb-14c48",
    storageBucket: "chatweb-14c48.appspot.com",
    messagingSenderId: "116363121663",
    appId: "1:116363121663:web:4a2a499f36124de8b38dca",
    measurementId: "G-JW1NMVD0RC"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BDeo0NPUvm0BjyuzXFfd7mbWbCW-DLp3qLyYmWl09ELXALxulqKvjro61nD_Dx2zVWorK0q34dPDFkw5qcx6L28" })
        .then((currentToken) => {
            if (currentToken) {
                localStorage.setItem("setfirebasetoken", currentToken);
                // console.log('current token for client:   ', currentToken);
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};
const OnMessageListener = () => {
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            displayNotification(payload);
        });
    });
}
function displayNotification(payload) {
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support notifications");
        return;

    }
    // Request permission from the user to display notifications
    Notification.requestPermission().then(permission => {
        if (permission === "granted") {
            const notification = new Notification(payload.data.userfullname, {
                body: payload.notification.body,
                icon: payload.data.compprofile
            });
            notification.onclick = (e) => {
                e.preventDefault();
                window.location.assign('chatgrid');
                notification.close();
            };
        } else {
            console.log("Permission for notifications denied by user");
        }
    });

}

// Call the OnMessageListener function to start listening for incoming messages
OnMessageListener();


export const NotifivcatioPush = {
    requestForToken, OnMessageListener
}

