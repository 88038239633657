// @ts-nocheck
import ROUTES from "../../chatRoutes/chatRoutes";
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    NEW_APP_LAUNCH,
} from "../constants/authConstant";
import { userInfoAction } from "./userInfoAction";

export const userLoginAction = (data) => async (dispatch) => {
    console.log(data, "daataa")
    try {
        dispatch({ type: LOGIN_REQUEST });
        // dispatch({ type: LOGIN_SUCCESS, payload: data });/
        if (data) {
            dispatch({ type: LOGIN_SUCCESS, payload: data });
        }
    } catch (error) {
        console.error("Login failed:", error);
        dispatch({ type: LOGIN_FAILED, payload: error.message || "Login failed" });
    }
};

// export const userLoginAction = (data) => async (dispatch) => {
//     dispatch({ type: LOGIN_REQUEST });
//     // Avoid unnecessary double dispatch if already processed
//     if (data) {
//         dispatch({ type: LOGIN_SUCCESS, payload: data });
//     }
// };

export const userChatLogoutAction = () => {
    return async (dispatch, getState) => {
        try {
            // Disconnect socket instance if available
            const state = getState();
            const socketInstance = state.socket?.socket;
            if (socketInstance) {
                socketInstance.disconnect();
                dispatch({
                    type: "SET_SOCKET",
                    payload: { socket: null, isConnected: false },
                });
            }
            // Clear user info and reset state
            await dispatch(userInfoAction({}));
            dispatch({ type: LOGOUT });
            localStorage.removeItem("chatToken"); // Clear token safely
            localStorage.removeItem("user_id"); // Clear token safely
            window.history.pushState({}, "", ROUTES.LOGIN);
        } catch (error) {
            console.error("Logout error:", error);
        }
    };
};

export const appVersionChangeAction = () => {
    return async (dispatch) => {
        try {
            localStorage.clear();
            await dispatch({ type: NEW_APP_LAUNCH });
        } catch (error) {
            console.error("Error resetting app version:", error);
        }
    };
};

export const userLoginSuccess = (userData, userInfo) => ({
    type: LOGIN_SUCCESS,
    payload: { userData, userInfo },
});
