// @ts-nocheck
import { useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPen, faCheck, faUser } from '@fortawesome/free-solid-svg-icons'
import { docurlchat } from "../../utils/configurable";
import GroupMember from "./GroupMember";
import React from "react";
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { PlusOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useFetch } from "../../Services/useFetch";


const Detail = ({ group_id, Setgroupinfoupdate }) => {
    const { userData } = useSelector((state) => state.loginData)
    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);
    const [value, setValue] = useState();
    const [Getgroupid, setgroupid] = useState();
    const [visible, setVisible] = useState(false);
    const [Getgroupinfo, setgroupinfo] = useState([false]);
    const [Getgrouuserlist, setgrouuserlist] = useState([false]);
    const [formDataField, setFormDataField] = useState({
        user_ids: [] // Initial value for user_ids
    });
    const { request } = useFetch()
    const [editMode, setEditMode] = useState(false);
    const [file, setFile] = useState("");
    // const [imagePreviewUrl, setImagePreviewUrl] = useState("https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true");
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const getgroupinfo = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('group_id', group_id?.group_id);
            const response = group_id?.group_id && await request('/group-chat-userlist', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == "1") {
                setgroupinfo(response?.data?.data)
            } else {
                setgroupinfo([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }
    useEffect(() => {
        getgroupinfo()
    }, [group_id?.group_id])

    useEffect(() => {
        setValue(group_id?.group_name);
    }, [group_id?.group_id])

    useEffect(() => {
        setImagePreviewUrl(docurlchat + '/' + group_id?.file)
    }, [group_id?.file])
    const get_userlist_of_group = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('group_id', Getgroupid);
            const response = Getgroupid && await request('/get-group-user-list', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == "1") {
                setgrouuserlist(response?.data?.data)
            } else {
                setgrouuserlist([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }
    useEffect(() => {
        get_userlist_of_group()
    }, [Getgroupid])
    const handleSaveClick = () => {
        setEditMode(false)
    }
    const handleEditClick = () => {
        setEditMode(true)
    }
    const handleInputChange = (e) => {
        setValue(e.target.value)
    }
    const handleButtonClick = (data) => {
        setVisible(true);
        setgroupid(data)
    };


    const handleModalClose = () => {
        setVisible(false);
    };
    const handleUserSelectChange = (value) => {
        setFormDataField({ ...formDataField, user_ids: value });
    };

    const handleSubmit = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('user_ids', formDataField?.user_ids);
            formData.append('group_id', Getgroupid);
            const response = await request('/update-user-in-group', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == "1") {
                notifySuccess('User Added Successfully done')
                setVisible(false);
                get_userlist_of_group()
                getgroupinfo()
            } else {
                notify('Something Went Wrong!')
                setVisible(true);
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }

    const handleCloseModel = () => {
        // setdeleteOpen(false)
    }

    const photoUpload = async (event) => {
        const files = event?.target?.files;
        if (files && files.length > 0) {
            const file = files[0];
            const filesWithPath = Array.from(files).map(file => ({
                file: file,
                type: 'image',
                path: URL.createObjectURL(file)
            }));

            setImagePreviewUrl(filesWithPath[0].path); // Update image preview URL
            const formData = new FormData();
            formData.append('file', file);
            formData.append('group_name', value);
            formData.append('group_id', group_id?.group_id);
            try {
                const response = await request('/update-grope-info', 'put', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
                if (response?.data?.status == "1") {
                    Setgroupinfoupdate({ group_id: group_id?.group_id, group_name: value, file: response?.data?.data?.group_profile })
                    notifySuccess('group info update succss fully done')


                } else {
                    notify('Something Went Wrong!')
                    setVisible(true);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                notify('Error uploading file');
            }
        }

    };

    const updategroupname = async () => {
        const formData = new FormData();
        formData.append('group_name', value);
        formData.append('group_id', group_id?.group_id);
        try {
            const response = await request('/update-grope-info', 'put', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == "1") {
                Setgroupinfoupdate({ group_id: group_id?.group_id, group_name: value, file: response?.data?.data?.group_profile })
                notifySuccess('group info update succss fully done')
                setEditMode(false)

            } else {
                notify('Something Went Wrong!')
                setVisible(true);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            notify('Error uploading file');
        }

    }

    return (
        <>
            <div className="detail">
                {/* <ImgUpload /> */}
                <div className="profile__wrapper">
                    <label className="custom-file-upload fas">
                        <div className="img-wrap img-upload">
                            <input id="photo-upload" type="file" onChange={(event) => photoUpload(event)} />
                            <img src={imagePreviewUrl} alt="Profile Preview" />
                        </div>

                    </label>
                    {/* <input type="file" onChange={(event) => photoUpload(event)} src={imagePreviewUrl} /> */}
                </div>
                <div className="user-names">
                    {editMode ?
                        <div className="input-box">
                            <input type="text" value={value} onChange={handleInputChange} />
                        </div>
                        :
                        <div className="edit_icons">
                            <h2>{value}</h2>
                        </div>

                    }
                    {(userData?.data?.id === Getgroupinfo?.group_info?.created_by) ?
                        <span onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                            {(editMode) ?
                                <SendOutlined onClick={() => updategroupname()} />
                                :
                                <EditOutlined />
                            }
                        </span>
                        : ""
                    }

                    <p>Created By-{Getgroupinfo?.group_info?.user?.userfullname + '(' + Getgroupinfo?.group_info?.user?.email + ')'}</p>
                </div>
                <div className="group-info">
                    <h2 style={{ fontSize: "24px", textTransform: "capitalize", paddingBottom: "24px" }}>Group Members
                        {(userData?.data?.id === Getgroupinfo?.group_info?.created_by) ?
                            <span onClick={() => handleButtonClick(group_id?.group_id)} style={{ cursor: 'pointer', marginLeft: '5px' }}>

                                <PlusOutlined />
                            </span>
                            : ""
                        }
                    </h2>
                    <GroupMember group_data={Getgroupinfo} />
                </div>
            </div >
            <Modal
                title="Add User" visible={visible} onCancel={handleModalClose} footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={handleSubmit} type="primary" >
                        Submit
                    </Button>,
                ]}
            >
                <Form>
                    <Form.Item label="User Assign">
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Tags Mode"
                            value={formDataField.user_ids}
                            onChange={handleUserSelectChange}

                        >
                            {Getgrouuserlist?.map((item, index) => (
                                <Select.Option key={index} value={item?.id}>
                                    {item?.userfullname}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>


        </>
    );

}
export default Detail;