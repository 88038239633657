// @ts-nocheck
import React from 'react';
import { Tabs, Switch } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';

const widgetList = [
    {
        "id": 1,
        "widget_name": "Personal Calendar",
        "status": "1"
    },
    {
        "id": 2,
        "widget_name": "My Team",
        "status": "1"
    },
    {
        "id": 3,
        "widget_name": "My Task",
        "status": "1"
    },
    {
        "id": 4,
        "widget_name": "Team Tasks List",
        "status": "1"
    },
    {
        "id": 5,
        "widget_name": "Department Calendar",
        "status": "1"
    },
    {
        "id": 6,
        "widget_name": "Financial Year Wise Amount",
        "status": "1"
    },
    {
        "id": 7,
        "widget_name": "OnGoing Bidding Graph",
        "status": "1"
    },
]


const WidgetTab = () => {
    return (
        <>
            <div className="roleManagement_wrapper">
                <div className="heading_flex">
                    <h1 className="head_title">Change Settings</h1>
                </div>

                <div className="tenderCycle_main">
                    <Tabs
                        tabPosition="left"
                        className="custom-tabs-container"
                    >
                        {
                            <TabPane
                            >
                                <div className="permissions_access_widget">
                                    {
                                        widgetList?.map((val, index) => {
                                            return (
                                                <div className='widgets_cards' key={index}>
                                                    <span>{val?.widget_name}</span>
                                                    <Switch />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default WidgetTab;


