// @ts-nocheck
import React from 'react'
import CustomProfile from './CustomProfile'


const Profile = () => {

    return (
        <>
            <CustomProfile isPersonal={true} />
        </>
    )
}

export default Profile