// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { docurlchat } from "../../utils/configurable";
import { useSelector } from 'react-redux';
import { Avatar, Modal } from 'antd';
import io from 'socket.io-client';
import { DownloadOutlined } from '@ant-design/icons';
import fileImage from '../../assests/img/documentation.png'
import docImg from '../../assests/img/pdf.png'
import xlsImg from '../../assests/img/xls.png'
import fileImg from "../../assests/img/file.png";
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';

import { useFetch } from '../../Services/useFetch';
import { Loading } from '@icon-park/react';
const bidgridloginid = localStorage.getItem('bidloginid');
const Chats = ({ propdata }) => {
    const { request } = useFetch()
    const { userData } = useSelector((state) => state.loginData)
    const { socket, isConnected } = useSelector((state) => state.socket);
    const messagesRef = useRef(null);
    const [messagesDelList, setChatingMsg] = useState([]);
    const [GettractDoubleTick, SettractDoubleTick] = useState(false);
    const [GettractDouble, SettractDouble] = useState(false);
    const [messagesList, setchatingmsg] = useState([]);


    const chatboaduser = localStorage.getItem('chatboaduser')
    const conversationDataHistory = async () => {
        try {
            if ((propdata?.propdata.user_id) || (chatboaduser)) {
                const formData = new URLSearchParams()
                if ((chatboaduser) && (!propdata?.propdata.user_id)) {
                    formData.append('user_id', chatboaduser)
                } else {
                    formData.append('user_id', propdata?.propdata.user_id)
                }
                const response = await request('/user-chating-list', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
                if (response?.data?.status === "1") {
                    const firstobject = propdata?.probschatreceive;
                    const secondObject = response?.data?.data
                    propdata?.setSentMessageSuccess(false)
                    setchatingmsg([...secondObject]);
                    if ((isConnected) && (!propdata?.getidsFortick)) {
                        socket.emit('track_select_user', { to_user_id: propdata?.propdata.user_id, from_user_id: userData?.data?.id });
                    }
                    propdata?.SetidsFortick(null)

                } else {
                    setchatingmsg([]);
                }
            }
        } catch (error) {
            console.log(error, 'api error');
        }
    };

    useEffect(() => {
        if (propdata?.getidsFortick) {
            conversationDataHistory();
        }
        propdata?.SetidsFortick(null)
    }, [propdata?.getidsFortick]);


    useEffect(() => {
        if (propdata?.sentMessageSuccess) {
            conversationDataHistory()
        }
    }, [propdata?.sentMessageSuccess])


    const conversationDataRealTime_Single = async () => {
        try {
            if (propdata?.probschatreceive[0]?.from_user_id == userData?.data?.id) {
                setchatingmsg([...messagesList, ...propdata.probschatreceive]);
            }
            if (propdata?.propdata?.user_id == propdata?.probschatreceive[0]?.from_user_id) {
                setchatingmsg([...messagesList, ...propdata.probschatreceive]);
            }
            // if (propdata.probschatreceive.length > 1) {
            //     const newMessagesList = [...messagesList];
            //     newMessagesList[0] = propdata.probschatreceive[0];
            //     setchatingmsg([...newMessagesList, ...propdata.probschatreceive.slice(1)]);
            // } else if (propdata.probschatreceive.length == 1) {
            //     setchatingmsg([...messagesList, ...propdata.probschatreceive]);
            // }
            // else {
            //     setchatingmsg([]);
            // }
            // setchatingmsg([...messagesList, ...propdata.probschatreceive]);
        }
        catch (error) {
            console.log(error, 'api error');
        }

    };


    useEffect(() => {

        conversationDataHistory();
    }, [GettractDouble]);

    const handleImageModalOk = () => {
        setIsImageModalOpen(false);
    };

    const handleImageModalCancel = () => {
        setIsImageModalOpen(false);
    };

    // const conversationDataRealTim_GRoup = async () => {
    //     try {

    //         if (propdata.probschatreceive_group_msg[0].group_id === propdata?.propdata.group_id) {
    //             if (propdata.probschatreceive_group_msg.length > 1) {
    //                 const newMessagesList = [...messagesList];
    //                 newMessagesList[0] = propdata.probschatreceive_group_msg[0];
    //                 setchatingmsg([...newMessagesList, ...propdata.probschatreceive_group_msg.slice(1)]);
    //             } else if (propdata.probschatreceive_group_msg.length == 1) {
    //                 setchatingmsg([...messagesList, ...propdata.probschatreceive_group_msg]);
    //             }
    //             else {
    //                 setchatingmsg([]);
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error, 'api error');
    //     }

    // };

    useEffect(() => {
        setChatingMsg(propdata?.probschatreceive || []);
    }, [propdata?.probschatreceive]);

    // useEffect(() => {
    //     setChatingMsg(propdata?.probschatreceive_group_msg || []);
    // }, [propdata?.probschatreceive_group_msg]);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messagesList]);


    useEffect(() => {
        conversationDataRealTime_Single();
    }, [propdata?.probschatreceive]);

    // useEffect(() => {
    //     conversationDataRealTim_GRoup();
    // }, [propdata?.probschatreceive_group_msg]);

    useEffect(() => {
        conversationDataHistory();
    }, [propdata?.propdata.user_id, chatboaduser]);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isImageGroupModalOpen, setIsImageGroupModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState();

    const showImageModal = (imgUrl) => {
        setModalImageSrc(imgUrl)
        setIsImageModalOpen(true);
    };
    const showImageGroupModal = () => {
        setIsImageGroupModalOpen(true);
    };

    const handleImageGroupModalOk = () => {
        setIsImageGroupModalOpen(false);
    };

    const handleImageGroupModalCancel = () => {
        setIsImageGroupModalOpen(false);
    };
    const handleDownload = (fileUrl) => {
        // Create a temporary link element
        fetch(fileUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ''); // Ensure download instead of opening in a new tab
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    };
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const options = { hour12: true, hour: 'numeric', minute: 'numeric' };
        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return date.toLocaleTimeString('en-US', options);
        } else {
            return date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
        }
    };
    return (
        <>
            {
                (messagesList.length > 0) ? (
                    <div className="msg__block" ref={messagesRef}>
                        {messagesList?.map((item, index) => (

                            <div key={index} className={`meg__item ${userData?.data?.id == item.from_user_id ? 'myChat' : ''}`} >
                                {item.is_file == 1 ? (
                                    <>
                                        {(item.file_type?.includes('image')) ? (

                                            <div className='uploaded-image' >
                                                {
                                                    <>
                                                        <div onClick={() => showImageModal(docurlchat + item.file_name)}>
                                                            <img className='w-100' src={docurlchat + item.file_name} />
                                                        </div>
                                                        <div className='file-details_image'>
                                                            <button onClick={() => handleDownload(docurlchat + item.file_name)}><DownloadOutlined /></button>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        ) : (
                                            <div className='uploaded-image'>
                                                <div className='doc-img'>
                                                    <img src={item.original_file_name.includes('.png') ? fileImg : item.original_file_name.includes('.pdf') ? docImg : item.original_file_name.includes('.xls') && xlsImg} />
                                                    <span>{item.original_file_name}</span>
                                                </div>
                                                <div className='file-details'>
                                                    <button onClick={() => handleDownload(docurlchat + item.file_name)}><DownloadOutlined /></button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {((item.read == '1') && (userData?.data?.id == item.from_user_id)) ?
                                            <div>&#10004;&#10004;</div>
                                            :
                                            ""
                                        }
                                        {((item.read == '0') && (userData?.data?.id == item.from_user_id)) ?
                                            <div>&#10004;</div>
                                            :
                                            ""
                                        }
                                        <div className="text">{item.message}</div>

                                    </>
                                )}
                                <div className="time">{formatDateTime(item.created_at)}
                                </div>
                            </div>
                        ))}
                    </div>
                ) :
                    <>
                        <div className="chatAnother_image">
                            <img src={fileImage} />
                            <div className="chatgrid_para">
                                <h5>Start a New Conversation!</h5>
                                <span>Type your first message in the box below to get started</span>
                            </div>
                        </div>
                    </>

            }
            <Modal
                open={isImageModalOpen}
                title="Image"
                style={{ top: 0 }}
                className='image-modal'
                onOk={handleImageModalOk}
                onCancel={handleImageModalCancel}
            >
                <div className='images-group-wrapper'>

                    <img src={modalImageSrc} className='w-100' />
                </div>
            </Modal>
        </>

    )
}

export default Chats
