import React, { useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import { ChatBaseUrl } from '../utils/configurable';
import { mainStore } from '../Redux/store';
import { userChatLogoutAction } from '../Redux/actions/authAction';

export function useFetch() {
    const abortControllers = useRef({});
    const axiosInstance = axios.create({
        baseURL: ChatBaseUrl,
        // timeout: 10000,
    });
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('chatToken');
            if (token) {
                config.headers['authorization'] = token;
            }

            if (abortControllers.current[config.url]) {
                abortControllers.current[config.url].abort();
            }
            abortControllers.current[config.url] = new AbortController();
            config.signal = abortControllers.current[config.url].signal;

            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token' || error?.response?.data?.message === "You have logged into another device") {
                mainStore.dispatch(userChatLogoutAction())
            }
            return Promise.reject(error);
        }
    );

    const request = useCallback(async (url, method, payload, headers) => {
        try {
            const options = {
                url,
                method,
                headers,
                signal: abortControllers.current.signal

            };
            if (['POST', 'PUT', 'DELETE'].includes(method.toUpperCase())) {
                options.data = payload;
            }
            const response = await axiosInstance(options);
            return response
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error during API call:', error);
            }
            return error
        }
    }, []);

    useEffect(() => {
        return () => {
            Object.values(abortControllers.current).forEach(controller => {
                controller.abort();
            });
        };
    }, []);

    return { request };
}
