// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { Avatar, Modal } from 'antd';
import fileImage from '../../assests/img/documentation.png'
import { docurlchat } from "../../utils/configurable";
import { DownloadOutlined } from '@ant-design/icons';
import { useFetch } from '../../Services/useFetch';
const Chats = ({ propdata }) => {
    const [messagesList, setchatingmsg] = useState([]);
    const { userData } = useSelector((state) => state.loginData)
    const [getGroupmassege, setGroupmassege] = useState([]);
    const { request } = useFetch()
    const conversationDataHistory = async () => {
        try {
            if (propdata?.propdata.group_id) {
                const formData = new URLSearchParams()
                formData.append('group_id', propdata?.propdata.group_id)
                const response = await request('/user-groupchatinglist-history', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
                if (response?.data?.status === "1") {
                    const firstobject = propdata?.probschatreceive;
                    const secondObject = response?.data?.data
                    setchatingmsg(secondObject);

                } else {
                    setchatingmsg([]);
                }

            }
        } catch (error) {
            console.log(error, 'api error');
        }

    };




    const conversationDataRealTim_GRoup = async () => {
        try {
            if (propdata?.probschatreceive_group_msg[0]?.group_id === propdata?.propdata?.group_id) {
                if (propdata?.probschatreceive_group_msg?.length > 1) {
                    const newMessagesList = [...messagesList];
                    newMessagesList[0] = propdata?.probschatreceive_group_msg[0];
                    setchatingmsg([...newMessagesList, ...propdata?.probschatreceive_group_msg.slice(1)]);
                } else if (propdata?.probschatreceive_group_msg?.length == 1) {
                    setchatingmsg([...messagesList, ...propdata?.probschatreceive_group_msg]);
                }
                else {
                    setchatingmsg([]);
                }
            }
        } catch (error) {
            console.log(error, 'api error');
        }

    };

    // useEffect(() => {
    //     conversationDataRealTime_Single();
    // }, [propdata?.probschatreceive]);

    useEffect(() => {
        conversationDataRealTim_GRoup();
    }, [propdata?.probschatreceive_group_msg]);

    const messagesRef = useRef(null);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messagesList]);

    useEffect(() => {
        conversationDataHistory();
    }, [propdata?.propdata.group_id]);

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const options = { hour12: true, hour: 'numeric', minute: 'numeric' };
        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return date.toLocaleTimeString('en-US', options);
        } else {
            return date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
        }
    };
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isImageGroupModalOpen, setIsImageGroupModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState();

    const showImageModal = (imgUrl) => {
        setModalImageSrc(imgUrl)
        setIsImageModalOpen(true);
    };

    const handleImageModalOk = () => {
        setIsImageModalOpen(false);
    };


    const handleImageModalCancel = () => {
        setIsImageModalOpen(false);
    };




    const showImageGroupModal = () => {
        setIsImageGroupModalOpen(true);
    };

    const handleImageGroupModalOk = () => {
        setIsImageGroupModalOpen(false);
    };

    const handleImageGroupModalCancel = () => {
        setIsImageGroupModalOpen(false);
    };
    const handleDownload = (fileUrl) => {
        // Create a temporary link element
        fetch(fileUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ''); // Ensure download instead of opening in a new tab
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    };
    return (
        <div className="msg__block" ref={messagesRef}>
            {
                messagesList?.map((item, index) => {
                    return (
                        <>
                            <div key={index} className={`meg__item ${userData?.data?.id === item.from_user_id ? 'myChat' : ''}`} >
                                {(userData?.data?.id !== item.from_user_id) ?
                                    <Avatar style={{ backgroundColor: '#87d068' }} size={32} src={docurlchat + item.user?.profileimg_path + '/' + item.user?.profileimg} />
                                    : ""
                                }
                                {item.is_file == 1 ? (
                                    <> {(item.file_type == 'image') ? (
                                        <>
                                            <div className='uploaded-image' >
                                                <div onClick={() => showImageModal(docurlchat + item.file_name)}>
                                                    <img className='w-100' src={docurlchat + item.file_name} />

                                                </div>
                                                <div className='file-details_image'>
                                                    <button onClick={() => handleDownload(docurlchat + item.file_name)}><DownloadOutlined /></button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='uploaded-image'>
                                            <div className='doc-img'>
                                                <span>{item.original_file_name}</span>
                                            </div>
                                            <div className='file-details'>
                                                <button onClick={() => handleDownload(docurlchat + item.file_name)}><DownloadOutlined /></button>
                                            </div>
                                        </div>
                                    )}

                                        {/* {item.file_type == 'image' ? (
                                            <div className='uploaded-image' onClick={() => showImageModal("https://plus.unsplash.com/premium_photo-1676977395734-b9a8de444073?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")}>
                                                <img className='w-100' src={'http://localhost:3010/' + item.single_chat_image} />
                                            </div>
                                        ) : (
                                            <div className='uploaded-image'>
                                                <div className='doc-img'>
                                                    <img src={'http://localhost:3010/' + 'uploads/public_4/chats/2/732104Document (2).pdf '} />
                                                </div>
                                                <div className='file-details'>
                                                
                                                    <a href={'http://localhost:3010/' + 'uploads/public_4/chats/2/732104Document (2).pdf '} download>Download</a>
                                                </div>
                                            </div>
                                        )} */}
                                    </>
                                ) : (
                                    <div className="text">
                                        <div dangerouslySetInnerHTML={{
                                            __html: (userData?.data?.id ===
                                                item.from_user_id) ? 'You :<br>' + item.message : item.user.userfullname + ' :<br>' + item.message
                                        }} /></div>
                                )}
                                <div className="time">{formatTime(item.created_at)}</div>
                            </div>
                        </>
                    )
                })
            }

            <Modal
                open={isImageModalOpen}
                title="Image"
                style={{ top: 0 }}
                className='image-modal'
                onOk={handleImageModalOk}
                onCancel={handleImageModalCancel}
            >
                <div className='images-group-wrapper'>

                    <img src={modalImageSrc} className='w-100' />
                </div>
            </Modal>

            {/* <Modal
                open={isImageGroupModalOpen}
                title="Images"
                style={{ top: 0 }}
                className='image-modal'
                onOk={handleImageGroupModalOk}
                onCancel={handleImageGroupModalCancel}
                footer={[
                ]}
            >
                <div className='images-group-wrapper'>
                    <div className="d-flex gap-4 flex-column">
                        {
                            imagesGroupArray.map((item, index) => (
                                <img src={item} className='w-100 rounded-3' key={index} />
                            ))
                        }
                    </div>
                </div>
            </Modal> */}
        </div >
    )
}

export default Chats
