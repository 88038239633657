import { FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILED, } from "../constants/userInfoConstant"

export const userInfoAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: FETCH_USER_INFO_REQUEST })
        dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: FETCH_USER_INFO_FAILED, payload: error })
    }
}


