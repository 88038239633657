// @ts-nocheck
import React, { lazy, Suspense, useState, useEffect } from 'react';
import io from 'socket.io-client';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './assests/css/app.scss';
import { useNavigate } from 'react-router-dom';
import Error404 from './pages/Error404/Error404';
import Profile from "./pages/Profile/Profile";
import Login from './pages/login/Login';
import './assests/css/style/style.css';
import './assests/css/style/styleResponsive.css';
import { ProtectedRoutesChat } from './utils/ProtectedRoutesChat';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { chat_socket_url, docurlchat } from './utils/configurable';
import RootLayout from './layout/RootLayout';
import { mainStore } from './Redux/store'
import ChatSettings from './pages/setting/ChatSetting'
import ROUTES from './chatRoutes/chatRoutes'
import ChatGrid from './Chat/ChatSection/ChatGrid';
import { Notification } from './pages/notification/notification'
const AdminApp = lazy(() => import('./Admin/adminApp'));


const ChatGridApp = () => {

  const dispatch = useDispatch()
  const { userData } = useSelector((state) => state.loginData)
  const { userInfo } = useSelector((state) => state?.userDetails)
  const navigate = useNavigate()
  const location = useLocation()
  const val = location.pathname


  const handleBeforeUnload = () => {
    const state = mainStore.getState();
    const socketInstance = state.socket.socket;

    if (socketInstance && socketInstance.connected) {
      socketInstance.disconnect();
    }
  };

  useEffect(() => {
    if (userData?.data?.id) {
      const socketInstance = io(chat_socket_url, {
        transports: ['websocket'],
        query: {
          user_id: userData?.data?.id
        }
      });
      socketInstance.on("connect", () => {
        dispatch({
          type: 'SET_SOCKET',
          payload: { socket: socketInstance, isConnected: socketInstance?.connected },
        });
      });
      window.addEventListener('beforeunload', handleBeforeUnload);
      socketInstance.on('disconnect', () => {
        dispatch({
          type: 'SET_SOCKET',
          payload: { socket: null, isConnected: false },
        });
      });
      return () => {
        if (socketInstance.connected) {
          socketInstance.disconnect();
        }
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [userData]);

  const checkAuth = () => {
    let authForLogin = localStorage.getItem('chatToken');
    let adminAuthToken = localStorage.getItem('adminToken');
    const currentPath = window.location.pathname;
    if (authForLogin) {
      if (currentPath === ROUTES?.LOGIN) {
        navigate(ROUTES?.CHATGRID, { replace: true })
      }
      if (currentPath === '/') {
        navigate(ROUTES?.CHATGRID, { replace: true })
      }
    } else if (currentPath.includes('/admin')) {
      if (adminAuthToken) {
        navigate('/admin/dashboard')
      } else {
        navigate('/admin/login')
      }
    }
    else {
      navigate(ROUTES?.LOGIN)
    }
  };

  useEffect(() => {
    checkAuth()
  }, [])

  useEffect(() => {
    document.title = 'Chat Grid'
  }, []);

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')
    if (favicon && userInfo?.thumbnailPath && userInfo?.thumbnailName) {
      favicon.href = `${docurlchat}${userInfo.thumbnailPath}/${userInfo.thumbnailName}`
    }
  }, [userInfo])

  return (
    <>
      <RootLayout>
        <Routes>
          <Route path='*' element={<Error404 />} />
          <Route path='/error' element={<Error404 />} />
          <Route path='/' element={<Login />} />
          <Route path={ROUTES?.LOGIN} element={<Login />} />
          <Route path={ROUTES?.SETTINGS} element={<ChatSettings />} />
          <Route path={ROUTES?.CHATGRID} element={<ProtectedRoutesChat><ChatGrid /></ProtectedRoutesChat>} />
          <Route path={ROUTES?.PROFILE} element={<ProtectedRoutesChat isAccessible={ROUTES?.PROFILE}><Profile /></ProtectedRoutesChat>} />
          <Route path={ROUTES?.NOTIFICATION} element={<ProtectedRoutesChat><Notification /></ProtectedRoutesChat>} />
          <Route path="/admin/*" element={<Suspense fallback={<div></div>}><AdminApp /></Suspense>} />
        </Routes>
      </RootLayout>
      <Suspense fallback={<div></div>}>
      </Suspense>
    </>



  )
}
export default ChatGridApp;


