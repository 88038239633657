import React, { Component } from "react";
import fileImage from '../../assests/img/chat (2).png'
class App extends Component {
    render() {
        return (
            <div className="chatAnother_image_sec">
                <img src={fileImage} />
                <div className="chatgrid_para">
                    <h5>ChatGrid Chat</h5>
                    <span>Click on a User or Group to start messaging!</span>
                </div>
            </div>
        );
    }
}

// class Person extends Component {
//     render() {
//         return (
//             <div>
//                 <p> Name: {this.props.name} </p>
//                 <p>EyeColor: {this.props.eyeColor}</p>
//                 <p>Age : {this.props.age} </p>
//             </div>
//         );
//     }
// }

// Person.defaultProps = {
//     name: "Rahul",
//     eyeColor: "deepblue",
//     age: "45",
// };

export default App;