import { useLocation } from "react-router-dom";
import React, { Suspense, lazy, useState } from 'react'
import ROUTES from "../chatRoutes/chatRoutes";
import Header from "./sharedLayout/Header";

const RootLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true)
    const location = useLocation()
    const changeCollapsed = () => {
        setCollapsed(!collapsed)
    }
    return (
        <>

            <div >
                <Suspense fallback={<div></div>}>
                    <div >
                        {(location.pathname !== ROUTES.LOGIN && location.pathname !== '/' && !location.pathname.includes('/admin')) &&
                            <Header changeCollapsed={changeCollapsed} collapsed={collapsed} />
                        }
                        {children}
                    </div>
                </Suspense>
            </div>
        </>
    )
}

export default RootLayout