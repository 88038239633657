// @ts-nocheck
import React, { useEffect, useState, useMemo } from 'react'
import '../../assests/css/style/new/header.scss'
import { useSelector } from 'react-redux'
import { Dropdown, Menu } from 'antd'
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar } from '@mui/material'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import ROUTES from '../../chatRoutes/chatRoutes'
import { userChatLogoutAction } from '../../Redux/actions/authAction'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { docurlchat } from '../../utils/configurable';
import { useFetch } from '../../Services/useFetch';

import { AutoComplete, Input } from 'antd';

const getRandomInt = (max, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join('.')
    .split('.')
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              Found {query} on{' '}
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {category}
              </a>
            </span>
            <span>results</span>
          </div>
        ),
      };
    });

const Header = ({ changeCollapsed, collapsed }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.userDetails)
  const { socket, isConnected } = useSelector((state) => state.socket);
  const [designation, setDesignation] = useState('')
  const [notification, setNotification] = useState([])
  // const { bidMenu } = useSelector((state) => state.bidMenuVal)
  const { request } = useFetch()
  const fetchDesignation = async () => {

    try {
      const formData = new URLSearchParams();
      formData.append('designation_id', userInfo?.designation_id)
      const response = await request('/designation-edit', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status === '1') {
        setDesignation(response?.data?.data?.designation_name)
      } else {
        setDesignation('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isConnected) {
      const socketListener = (data) => {
        setNotification(prevComments => [data, ...prevComments.slice(0)]);
      }
      socket.on(userInfo?.id, socketListener);
      socket.on(`is_refresh_${userInfo?.id}`, socketListener);

      return () => {
        socket.off(userInfo?.id, socketListener);
      };

    }
  }, [isConnected])

  const getUserHistoryApi = async (shouldOpen) => {
    try {
      const response = await request('/notification-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status === '1') {
        setNotification(response?.data?.data)
        shouldOpen && setIsDropdownOpen(true)
      } else {
        setNotification([])
      }
    } catch (error) {
      setNotification([])
    }
  }

  useEffect(() => {
    if (localStorage.getItem('bidToken')) {
      fetchDesignation()
      getUserHistoryApi(false)
    }

  }, [])



  const handleNavigate = async (item) => {

    const obj = {
      item: item,
      type: item.category_types,
      key: item?.category_id,
      remarks: item?.remarks
    }
    // dispatch(navigationData.setNavigationDataAction(obj))
    if (
      item.category_types === 'tender_todo_comments' ||
      item.category_types === 'tender_comments' ||
      item.category_types === 'meeting_schedule' ||
      item.category_types === 'tender_request' ||
      item.category_types === 'tender_assign_manager' ||
      (item?.remarks?.includes('project') && item?.category_types === 'create_task') ||
      (item?.remarks?.includes('project') && item?.category_types === 'todo_scope_change') ||
      item?.category_types === 'scope_change' ||
      item?.category_types === 'tender_todo_scope_change'
    ) {
      navigate(ROUTES.BD_TENDERDETAILS.replace(':id', item?.tender_id));
    } else if (item.category_types === 'to_do_comments' || item?.category_types === "todo_scope_change" || (!item?.remarks?.includes('project') && item?.category_types === "create_task")) {
      navigate('/bidgrid/todo');
    }
    try {
      const formData = new URLSearchParams()
      formData.append('notification_id', item?.id)
      const response = await request('/notification-clicked', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status === '1') {
        getUserHistoryApi(false)
      }
    } catch (error) {

    }

  }

  function convertHtmlToText(item) {
    return item?.replace(/<[^>]*>/g, '')
  }

  const getBreadcrumbs = useMemo(() => (item) => {
    let string;
    switch (item?.category_types) {
      case 'tender_assign_manager':
        string = 'Tender Detail / Assign Manager';
        break;
      case 'create_task':
        string = item?.remarks?.includes('project') ? 'Tender Detail / TO-DO' : 'TO-DO';
        break;
      case 'tender_request':
        string = 'Tender Detail / Tender Request';
        break;
      case 'tender_comments':
        string = 'Tender Detail / Comments';
        break;
      case 'meeting_schedule':
        string = 'Tender Detail / Tender Meeting';
        break;
      case 'tender_todo_comments':
        string = 'Tender Detail / TO-DO Comment';
        break;
      case 'to_do_comments':
        string = 'TO-DO Comment';
        break;
      case 'scope_change':
        string = 'Tender / Scope Change';
        break;
      case 'tender_todo_scope_change':
        string = 'Tender Detail / TO-DO Scope change';
        break;
      case 'todo_scope_change':
        string = 'TO-DO Scope change';
        break;
      default:
        string = '';
        break;
    }
    return string;
  }, [notification]);

  const items = notification?.map((item, index) => ({
    label:
      <div onClick={() => handleNavigate(item)} className={`notification-col py-3 px-4 ${item.is_clicked == '1' && "viewed"}`}>
        <div className="d-flex align-items-start justify-content-between gap-2">
          <p className='notification-crumb mb-2'>{getBreadcrumbs(item)}</p>
          <p>{dayjs(item?.created_at).format('MMMM D, YYYY h:mm A')}</p>
        </div>
        <div className='d-flex gap-4 align-items-start'>
          <Avatar
            alt={item?.mentioned_by}
            src="/broken-image.jpg"
            className='mt-2'
          >
            {item?.mentioned_by?.slice(0, 2)}
          </Avatar>
          <div className='w-50 flex-grow-1'>
            <div className="d-flex justify-content-between mb-3">
              <h5 className='mention-by'>{item?.mentioned_by}</h5>
              {item.is_clicked !== '1' &&
                <div className='unread-circle' />
              }
            </div>
            <p className='notification-content mb-2'>{convertHtmlToText(item?.category_details)}</p>
            <p className='notification-remark mb-2'>{item?.remarks}</p>
          </div>
        </div>

      </div>,
    key: index.toString(),
  }));

  const handleLogout = () => {
    dispatch(userChatLogoutAction());

  };

  const handleClick = async () => {
    if (isDropdownOpen) {
      return
    }
    try {
      const response = await request('/notification-seen', 'put', null, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status === '1') {
        const updatedData = notification.map((item) => {
          return { ...item, ['is_viewed']: '1' };
        });
        setNotification(updatedData)
        setIsDropdownOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const val = location?.pathname;
  const str = val.replace('/', '');



  const menu = (
    <Menu className='px-2'>
      {/* <p>{userInfo?.userfullname}</p> */}
      <Menu.Item className='my-2 py-2 px-3 rounded-4' key="1" icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M24 27a8 8 0 1 0 0-16a8 8 0 0 0 0 16Zm0-2a6 6 0 1 0 0-12a6 6 0 0 0 0 12Z" />
            <path d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24S12.954 4 24 4s20 8.954 20 20ZM33.63 39.21A17.915 17.915 0 0 1 24 42a17.916 17.916 0 0 1-9.832-2.92c-.24-.3-.483-.61-.73-.93A2.144 2.144 0 0 1 13 36.845c0-1.077.774-1.98 1.809-2.131c6.845-1 11.558-.914 18.412.035A2.077 2.077 0 0 1 35 36.818c0 .48-.165.946-.463 1.31c-.307.374-.61.735-.907 1.082Zm3.355-2.744c-.16-1.872-1.581-3.434-3.49-3.698c-7.016-.971-11.92-1.064-18.975-.033c-1.92.28-3.335 1.856-3.503 3.733A17.94 17.94 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18a17.94 17.94 0 0 1-5.015 12.466Z" />
          </g>
        </svg>

      } onClick={() => navigate(ROUTES?.PROFILE)}>Profile</Menu.Item>
      {/* <Menu.Item className='my-2 py-2 px-3 rounded-4' key="1" icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M24 27a8 8 0 1 0 0-16a8 8 0 0 0 0 16Zm0-2a6 6 0 1 0 0-12a6 6 0 0 0 0 12Z" />
            <path d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24S12.954 4 24 4s20 8.954 20 20ZM33.63 39.21A17.915 17.915 0 0 1 24 42a17.916 17.916 0 0 1-9.832-2.92c-.24-.3-.483-.61-.73-.93A2.144 2.144 0 0 1 13 36.845c0-1.077.774-1.98 1.809-2.131c6.845-1 11.558-.914 18.412.035A2.077 2.077 0 0 1 35 36.818c0 .48-.165.946-.463 1.31c-.307.374-.61.735-.907 1.082Zm3.355-2.744c-.16-1.872-1.581-3.434-3.49-3.698c-7.016-.971-11.92-1.064-18.975-.033c-1.92.28-3.335 1.856-3.503 3.733A17.94 17.94 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18a17.94 17.94 0 0 1-5.015 12.466Z" />
          </g>
        </svg>

      } onClick={() => navigate(ROUTES?.SETTINGS)}>Settings</Menu.Item> */}
      {userInfo?.bg_mstr_role?.role_name === 'System Admin' &&

        <>
          {/* {
            bidMenu?.filter(item => item?.menu_name === 'Setting')?.map((val, index) => {
              return ( */}
          <>
            <Menu.Item
              className="my-2 py-2 px-3 rounded-4"
              key="2"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                  <path d="M38.1 22.5h-2.35c-.2-.9-.55-1.7-.95-2.5l1.65-1.65c.75-.75.75-1.9 0-2.65l-2.8-2.8c-.75-.75-1.9-.75-2.65 0l-1.65 1.65c-.8-.4-1.6-.75-2.5-.95V9.9c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2.35c-.9.2-1.7.55-2.5.95L14.6 11.6c-.75-.75-1.9-.75-2.65 0l-2.8 2.8c-.75.75-.75 1.9 0 2.65l1.65 1.65c-.4.8-.75 1.6-.95 2.5H9.9c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h2.35c.2.9.55 1.7.95 2.5l-1.65 1.65c-.75.75-.75 1.9 0 2.65l2.8 2.8c.75.75 1.9.75 2.65 0l1.65-1.65c.8.4 1.6.75 2.5.95V38.1c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2v-2.35c.9-.2 1.7-.55 2.5-.95l1.65 1.65c.75.75 1.9.75 2.65 0l2.8-2.8c.75-.75.75-1.9 0-2.65l-1.65-1.65c.4-.8.75-1.6.95-2.5h2.35c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zm-14.1 6c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" />
                </svg>
              }
              onClick={() => navigate(ROUTES?.SETTINGS)}
            >
              {"Setting"}
            </Menu.Item>
          </>
          {/* )
            }) */}

          {/* } */}
        </>


      }

      <Menu.Item className='my-2 py-2 px-3 rounded-4' key="6" danger icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.496 21H6.5c-1.105 0-2-1.151-2-2.571V5.57c0-1.419.895-2.57 2-2.57h7M16 15.5l3.5-3.5L16 8.5m-6.5 3.496h10" />
        </svg>
      } onClick={handleLogout}>
        LogOut
      </Menu.Item>
    </Menu>
  );

  // autoComplete
  const [options, setOptions] = useState([]);
  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };
  const onSelect = (value) => {
  };
  return (
    <>
      <header className='header'>
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}>
          <div className="headerDeyt">
            {/* <h4>{userInfo?.userfullname} <span>{designation !== '' ? `(${designation})` : ''}</span>
            </h4> */}
            <h4>{'Chatgrid Chats'} <span>{designation !== '' ? `(${designation})` : ''}</span>
            </h4>

            {
              window?.location?.pathname !== '/chatgrid' &&
              <p onClick={() => navigate('/chatgrid')} style={{ cursor: 'pointer' }} > ChatGrid</p>
            }
          </div>
          <div className="pageSearch">
          </div>
          <div className='d-flex gap-3'>
            {/* <div className="notification_wrap">
              <Dropdown
                menu={{
                  items,
                }}
                open={isDropdownOpen} // Pass the visibility state as a prop
                onOpenChange={setIsDropdownOpen}
                dropdownRender={(menu) => (
                  <div className="notification_Dropdown">
                    <div className="noti_header">Notification</div>
                    {React.cloneElement(menu, {
                      // style: menuStyle,
                    })}
                    <div className="noti_footer" onClick={() => setIsDropdownOpen(false)}><Link to={ROUTES?.NOTIFICATION}>View all</Link ></div>
                  </div>
                )}
                trigger={['click']}
                placement="bottomRight"

              // arrow
              >
                <div onClick={() => handleClick()} className='ot-btn'>
                  <span className="countNum">{notification?.filter(val => val.is_viewed === '0')?.length}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                    <path fill="currentColor" fill-rule="evenodd" d="M2.316 12h10.368c-.188-.704-.28-1.691-.348-3.037c-.07-1.382-.103-1.888-.19-2.612c-.028-.236-.06-.462-.096-.68c-.31-1.892-1.506-2.923-3.708-3.131a1 1 0 1 0-1.684 0c-2.202.208-3.397 1.24-3.708 3.13a16.01 16.01 0 0 0-.096.68c-.087.725-.12 1.23-.19 2.613c-.068 1.346-.16 2.333-.348 3.037m10.843 1H1.84c-.308.353-.737.5-1.341.5a.5.5 0 1 1 0-1c.786 0 1.024-.783 1.166-3.587c.07-1.407.105-1.926.196-2.681c.03-.25.063-.49.102-.724c.334-2.041 1.546-3.313 3.556-3.792a2 2 0 0 1 3.96 0c2.01.479 3.222 1.75 3.557 3.792a17 17 0 0 1 .102.724c.09.755.125 1.274.196 2.681c.14 2.804.379 3.587 1.165 3.587a.5.5 0 1 1 0 1c-.604 0-1.033-.147-1.341-.5M5.5 14h4a2 2 0 1 1-4 0" />
                  </svg>
                </div>
              </Dropdown>
            </div> */}
            <Dropdown overlay={menu} trigger={['click']}>
              <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <Avatar
                  alt={userInfo?.userfullname}
                  className='bid-avatar'
                  src={docurlchat + userInfo.profileimg_path + "/" + userInfo.profileimg}
                >
                  {userInfo?.userfullname?.charAt(0)}
                </Avatar>
                <DownOutlined style={{ marginLeft: '8px' }} />
              </span>
            </Dropdown>
          </div>
        </div>
      </header>
      <div className="new-breadcrumb">

      </div>
    </>
  )
}

export default Header