// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserList from '../../Chat/components/userList';
import Chats from '../../Chat/components/chats';
import Chatsgroup from '../../Chat/components/chatsgroup';
import AnotherComponent from '../../Chat/components/anotherComponent';
import { docurlchat } from "../../utils/configurable";
import Profile from '../../Chat/components/profile';
// import { profileImageAuth } from 'utils/configurable';
import "../../assests/css/chatApp.scss"
import profileimg from "../../assests/img/profile.jpg";
import message from "../../assests/img/chat-bubble.png";
import menu from "../../assests/img/dots.png";
import photo from "../../assests/img/photo.png";
import attach from "../../assests/img/attach.png";
import sendIcon from "../../assests/img/send.png";
import Detail from '../../Chat/components/Detail';
import VideoCall from '../../assests/img/videocam.png';
import PhoneCall from '../../assests/img/Phone.png';
import MicIcon from '../../assests/img/mic.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import io from 'socket.io-client';
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { CloseCircleOutlined, CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons';

import fileImage from "../../assests/img/documentation.png"
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Upload, Avatar, Progress } from 'antd';
import { useFetch } from '../../Services/useFetch';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


// socket connection of server End
const authorization = localStorage.getItem('bidToken');
function ChatGrid() {
  const location = useLocation();

  const { userData } = useSelector((state) => state.loginData)
  const { socket, isConnected } = useSelector((state) => state.socket);
  const { request } = useFetch()

  const initialState = {
    files: [],
  }
  const [form] = Form.useForm();
  const fileInputRef = useRef(null);

  const notify = (message, key, duration = 2000) => {
    // Check if the toast with the same key already exists
    if (!toast.isActive(key)) {
      toast.error(message, {
        toastId: key,         // Unique ID to prevent duplicates
        autoClose: duration,  // Duration for autoClose in ms (defaults to 2000ms)
        position: "top-right", // Position of the toast
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const { userInfo } = useSelector((state) => state.userDetails)
  const [dropdownState1, setDropdownState1] = useState(false);
  const [getUserId, setsetUserId] = useState('');
  const [getGroupID, setsetGroupID] = useState('');
  const [dropdownState2, setDropdownState2] = useState(false);
  const dropdownRef = useRef(null);
  const drawerRef = useRef(null);
  // const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [recipient, setRecipient] = useState([]);
  // const [recipientId, setRecipientId] = useState();
  const [getgroup_name, setgetgroup_name] = useState([]);
  const [getgroupmsg, setgroupmsg] = useState([]);
  const [getselectedFilesSingleChat, setSelectedFilesSingleChat] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [hide, setHide] = useState(false)
  const [onlineUsers, setOnlineUsers] = useState([null]);
  const [getsingleChatimageUpload, setsingleChatimageUpload] = useState([]);
  const [getsingleChatfileUpload, setsingleChatfileUpload] = useState([]);
  const [getsortinguserids, setsortinguserids] = useState([]);
  const [getsortinggroupids, setsortinggroupids] = useState([]);
  const [Gettypinguser, Settypinguser] = useState('');
  const [Gettypinggroup, Settypinggroup] = useState([]);
  const [inputKey, setInputKey] = useState(0);
  const [getgroupinfoupdate, Setgroupinfoupdate] = useState(false);
  const [getidsFortick, SetidsFortick] = useState(null);
  const [Getgropelist, setGetgropelist] = useState([])
  const [sentMessageSuccess, setSentMessageSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0);


  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };
  const handlegroup_nameChange = (e) => {
    setFormData({
      ...formDataField,
      group_name: e.target.value
    });
  };
  const handleUserSelectChange = (selectedUserIds) => {
    setFormData({
      ...formDataField,
      user_ids: selectedUserIds
    });
  };
  //############################ socket code  start ##############################
  useEffect(() => {
    if (isConnected) {
      socket.emit('join', userData?.data?.id);
      socket.on('userStatus', (data) => {
        setOnlineUsers(data)
      });
    }
  }, [isConnected]);
  const sendMessage = async () => {
    // debugger
    if (socket && socket.connected) {
      if (((getsingleChatfileUpload.length > 0) || (getsingleChatimageUpload.length > 0)) && (getUserId.user_id)) {
        const token = localStorage.getItem('chatToken');
        setLoading(true)
        const formData = new FormData();
        if (getsingleChatimageUpload.length > 0) {
          getsingleChatimageUpload.forEach(fileWithPath => {
            formData.append('file_type', fileWithPath.type);
            formData.append('file', fileWithPath.file);
          });
        } else if (getsingleChatfileUpload.length > 0) {
          getsingleChatfileUpload.forEach(fileWithPath => {
            formData.append('file_type', fileWithPath.type);
            formData.append('file', fileWithPath.file);
          });
        }
        formData.append('to_user_id', getUserId.user_id);
        try {
          const result = await axios.post(
            'https://chat-api.growthgrids.com/api/user/single-cahting-uploading-file',
            formData,
            {
              headers: {
                'Authorization': token,
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
            },
          );
          if (result?.data?.status == 1) {
            const data_single_chat = result?.data?.files;
            // setTimeout(() => {
            setSentMessageSuccess(true)

            // }, 1500);
            setLoading(false)
            // setSentMessageSuccess(true)
            setUploadFilePopup(false);
            setsingleChatimageUpload([])
            setsingleChatfileUpload([])
            socket.emit('send_file', { to_user_id: getUserId.user_id, comp_id: userData?.data?.comp_id, from_user_id: userData?.data?.id, single_chat_image: data_single_chat[0].file_name, created_at: data_single_chat[0].created_at, file_type: data_single_chat[0].file_type, original_file_name: data_single_chat[0].original_file_name, authorization: authorization });
          } else {
            setLoading(false)
            notify('The file size exceeds the allowed limit. Please upload a smaller file.', "jnsdjnj");
            console.log("error is here")
          }
        } catch (error) {
          setLoading(false)
          console.error("Error:", error);
        }
        // await request('/single-cahting-uploading-file', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
        //   .then(response => {
        //     if (response?.data?.status == 1) {
        //       const data_single_chat = response?.data?.files;
        //       // setTimeout(() => {
        //       setSentMessageSuccess(true)

        //       // }, 1500);
        //       setLoading(false)
        //       // setSentMessageSuccess(true)
        //       setUploadFilePopup(false);
        //       setsingleChatimageUpload([])
        //       setsingleChatfileUpload([])
        //       socket.emit('send_file', { to_user_id: getUserId.user_id, comp_id: userData?.data?.comp_id, from_user_id: userData?.data?.id, single_chat_image: data_single_chat[0].file_name, created_at: data_single_chat[0].created_at, file_type: data_single_chat[0].file_type, original_file_name: data_single_chat[0].original_file_name, authorization: authorization });
        //     } else {
        //       setLoading(false)
        //       notify('The file size exceeds the allowed limit. Please upload a smaller file.', "jnsdjnj");
        //       console.log("error is here")
        //     }
        //   })
        //   .catch(error => {
        //     setLoading(false)
        //     console.error("Error:", error);
        //   });
      } else if (((getsingleChatfileUpload.length > 0) || (getsingleChatimageUpload.length > 0)) && (getUserId.group_id)) {
        const formData = new FormData();
        if (getsingleChatimageUpload.length > 0) {
          getsingleChatimageUpload.forEach(fileWithPath => {
            formData.append('file_type', fileWithPath.type);
            formData.append('files', fileWithPath.file);
          });
        } else if (getsingleChatfileUpload.length > 0) {
          getsingleChatfileUpload.forEach(fileWithPath => {
            formData.append('file_type', fileWithPath.type);
            formData.append('files', fileWithPath.file);
          });
        }
        formData.append('group_id', getUserId?.group_id);
        await request('/group-cahting-uploading-file', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
          .then(response => {
            if (response?.data?.status == 1) {
              const data_single_chat = response?.data?.data;
              setUploadFilePopup(false);
              setsingleChatimageUpload([])
              setsingleChatfileUpload([])
              // socket.emit('joinRoom_file', getUserId.group_id);
              socket.emit('send_group_file', {
                group_id: getUserId?.group_id,
                single_chat_image: data_single_chat[0].file_name,
                file_type: data_single_chat[0].file_type,
                created_at: data_single_chat[0].created_at,
                original_file_name: data_single_chat[0].original_file_name,
              });
            }
          })
          .catch(error => {
            console.error("Error:", error);
          });

      }

      if ((message) && (getUserId.user_id)) {
        socket.emit('send', { to_user_id: getUserId.user_id, comp_id: userData?.data?.comp_id, from_user_id: userData?.data?.id, message, authorization: authorization });

        setMessages(
          (prevMessages) =>
            [...prevMessages, { from: 'Me', message }]);
        setMessage('');
      }
      if ((message) && (getUserId.group_id)) {
        socket.emit('joinRoom', getUserId.group_id);
        socket.emit('send_group_message', {
          group_id: getUserId?.group_id,
          message: message,
        });
        setMessages(
          (prevMessages) =>
            [...prevMessages, { from: 'Me', message }]);
        setMessage('');
      }
    }
    else {
      setError('Socket is not connected');
    }
    setHide(true)

  };
  useEffect(() => {
    if (isConnected) {
      socket.on('connect_error', (err) => {
        setError(`Connection error: ${err.message}`);
      });
      socket.on('receive', (data) => {
        setRecipient([data]);
      });
      socket.on('received', (data) => {
        setRecipient([data]);
      });
      socket.on('receive_group_message', (data) => {
        setgroupmsg([data]);
      });
      socket.on('received_group_message', (data) => {
        setgroupmsg([data]);
      });
      socket.on('receive_sorting_ids', (data) => {
        setsortinguserids([data]);
      });
      socket.on('received_sorting_ids', (data) => {
        setsortinguserids([data]);
      });
      socket.on('received_typing_event_start', (data) => {
        Settypinguser(data)
      });
      socket.on('received_typing_event_end', (data) => {
        Settypinguser('')
      });
      socket.on('received_group_typing_event_start', (data) => {
        Settypinggroup([data])
      });
      socket.on('received_group_typing_event_end', (data) => {
        Settypinggroup([data])
      });
      socket.on('received_track_select_user', (data) => {
        SetidsFortick(data)
      })



    }
    // setSocket(socket);
    return () => {
      // socket?.disconnect();
    };
  }, [isConnected]);
  useEffect(() => {
    if (isConnected) {
      socket.emit('typing_event_start', { to_user_id: getUserId?.user_id, from_user_id: userData?.data?.id });
    }
  }, [message]);
  useEffect(() => {
    if (isConnected && (!message)) {
      socket.emit('typing_event_End', { to_user_id: getUserId?.user_id, from_user_id: userData?.data?.id });
    }
  }, [!message]);
  useEffect(() => {
    if (isConnected) {
      socket.emit('group_typing_event_start', { group_id: getUserId?.group_id, from_user_id: userData?.data?.id });
    }
  }, [message]);
  useEffect(() => {
    if (isConnected && (!message)) {
      socket.emit('group_typing_event_end', { group_id: getUserId?.group_id, from_user_id: userData?.data?.id });
    }
  }, [!message]);
  const propdata = (val) => {
    setsetUserId(val)
  }
  // ######################### socket code  End #############################
  const handleDropdownClick1 = () => {
    setDropdownState1(!dropdownState1);
  };

  const handleDropdownClick2 = () => {
    setDropdownState2(!dropdownState2);
  };
  const [openProfile, setOpenProfile] = useState(false);
  const [openProfile1, setOpenProfile1] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const openDrawer = () => {
    setOpenProfile(true);
    setBackDrop(true)
    document.body.style.overflow = "hidden";
  }
  const drawerClose = () => {
    setOpenProfile(false);
    setBackDrop(false)
    document.body.style.overflow = "auto";
  }
  // right-side drawer
  const openDrawer1 = () => {
    setOpenProfile1(true);
    setBackDrop(true)
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setOpenProfile1(false); // Close the drawer
      }
    };

    if (openProfile1) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openProfile1]);

  const drawerClose1 = () => {
    setOpenProfile1(false);
    setBackDrop(false)
    document.body.style.overflow = "auto";
  }
  //GROUP################# CREATE#MODEL START##############

  const getUserList = async () => {
    try {
      const response = await request('/chat-user-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status == "1") {
        // ...Getgropelist,
        setuserListl(response?.data?.data)
      } else {
        setuserListl([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }
  useEffect(() => {
    getUserList()
  }, [])


  const data = location?.state?.data;
  const dataset = () => {
    setsetUserId(data)
  }
  useEffect(() => {
    dataset()
  }, [data])
  const [getuserlist, setuserListl] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  //create group
  const [formDataField, setFormData] = useState({
    group_name: '', // value for the group name
    user_ids: []// array to store selected user IDs
  });
  const showModal = () => {
    // formDataField.group_name = '';
    // formDataField.user_ids = [];
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getgroupList = async () => {
    try {
      const response = await request('/user-chatgroupmember-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status == "1") {
        // ...response?.data?.data]
        setGetgropelist(response?.data?.data)
      } else {
        setGetgropelist([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const CreateGroup = async () => {
    if ((!formDataField.group_name) || (!formDataField.user_ids)) {
      notify('All Fild are needed');
    }
    if ((formDataField.group_name) && formDataField.user_ids) {
      const formData = new URLSearchParams();
      formData.append('group_name', formDataField.group_name);
      formData.append('user_id', formDataField.user_ids);
      const response = await request('/user-chatgroup-create', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status === "1") {
        getgroupList()
        setgetgroup_name(formDataField)
        setIsModalOpen(false);
        notifySuccess('Group Create SuccessFully Done')
      }
    }
  }

  const handleBackdrop = () => {
    if (backDrop) {
      drawerClose()
      drawerClose1()
    }
  }

  const [preview, setPreview] = useState()
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    var selectedFileArray = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const currentFile = selectedFiles[i];
      selectedFileArray.push(currentFile);
    }
    setPreview(URL.createObjectURL(event.target.files[0]));
    setSelectedFilesSingleChat(selectedFileArray);
  };
  const cancelFile = (index) => {
    const updatedFiles = [...getselectedFilesSingleChat];
    updatedFiles.splice(index, 1);
    setSelectedFilesSingleChat(updatedFiles);
  };


  // upload file
  const [uploadFilePopup, setUploadFilePopup] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const handleCancelUpload = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  function valueExistsInArrays_online(array, value) {
    if (!array || !array?.data || array?.data?.length === 0) {
      return false; // Return false when array.data is empty or undefined
    }
    const data_match = value;
    if (getUserId?.user_id) {
      const isIdMatched = array?.data?.includes(data_match);
      return isIdMatched;
    }
  }
  const exists_online_offline = valueExistsInArrays_online(onlineUsers, `${getUserId?.user_id}`);
  const handleButtonClickuploadImage = (event) => {
    setsingleChatimageUpload([])
    setsingleChatfileUpload([])
    const files = event.target.files;
    if (files && files.length > 0) {
      const filesWithPath = Array.from(files).map(file => ({
        file: file,
        type: 'image',
        path: URL.createObjectURL(file)
      }));
      setsingleChatimageUpload(filesWithPath);
    }
    // fileInputRef.current?.click();
    setUploadFilePopup(true)
    event.target.value = '';

    // Increment the key to force re-rendering of the input element
    setInputKey(prevKey => prevKey + 1);
  };
  const handleRemoveimage = (indexToRemove) => {
    setsingleChatimageUpload(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    if (getsingleChatimageUpload.length < 2) {
      setUploadFilePopup(false);
      setsingleChatimageUpload([])
      setsingleChatfileUpload([])
    }
  };
  const handleRemoveFile = (indexToRemove) => {
    setsingleChatfileUpload(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    if (getsingleChatfileUpload.length < 2) {
      setUploadFilePopup(false);
      setsingleChatimageUpload([])
      setsingleChatfileUpload([])
    }
  };
  const handleRemovebtn = () => {
    setUploadFilePopup(false);
    setsingleChatimageUpload([])
    setsingleChatfileUpload([])
  }

  const handleButtonClickuploadfile = (event) => {
    setsingleChatimageUpload([])
    setsingleChatfileUpload([])
    const files = event?.target?.files;
    if (files && files.length > 0) {
      const filesWithPath = Array.from(files).map(file => ({
        file: file, // Not 'files'
        type: 'file',
        path: URL.createObjectURL(file)
      }));
      setsingleChatfileUpload(filesWithPath);
    }
    setUploadFilePopup(true)
    event.target.value = '';

    // Increment the key to force re-rendering of the input element
    setInputKey(prevKey => prevKey + 1);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);


  return (
    <>
      <div className="main_wrapper">
        <div className="grid h-100">
          <div className="user-list-section">
            <div className="chat_sideBar">
              {/* <div className="drawer_box" style={openProfile ? { left: 0 } : null}>
                <div className="btn__close" onClick={drawerClose}>close</div>
                <Profile />
              </div> */}
              <div className="userProfile" style={{ height: isSearchBarVisible ? 120 + "px" : 64 + "px", overflow: "hidden" }}>
                <div style={{
                  height: "64px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 10px",
                }}>
                  {/* <h5>Bidgrid Chats</h5> */}
                  <h5>{userInfo?.userfullname}</h5>
                  <div className="d-flex gap-1">
                    <button className='unstyled-button' onClick={showModal}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12.692 11.335q.552-.589.776-1.364q.224-.775.224-1.586q0-.812-.224-1.587q-.224-.775-.776-1.363q1.135.103 1.875.95q.74.846.74 2q0 1.153-.74 2q-.74.846-1.875.95Zm5 7.28v-1.692q0-.735-.265-1.4q-.265-.663-.839-1.096q1.025.315 1.873.903q.847.587.847 1.593v1.692h-1.616Zm1.616-6.115v-2h-2v-1h2v-2h1v2h2v1h-2v2h-1ZM8.692 11.385q-1.237 0-2.118-.882t-.882-2.118q0-1.238.882-2.12q.88-.88 2.118-.88t2.119.88q.881.882.881 2.12q0 1.237-.881 2.118t-2.119.882Zm-7 7.23V16.97q0-.619.36-1.158q.361-.54.971-.838q1.415-.679 2.833-1.018q1.417-.34 2.836-.34q1.42 0 2.837.34q1.417.34 2.833 1.018q.61.298.97.838q.36.539.36 1.158v1.646h-14Zm7-8.23q.825 0 1.413-.588t.587-1.412q0-.825-.587-1.413t-1.413-.587q-.825 0-1.412.587t-.588 1.413q0 .825.588 1.412t1.412.588Zm-6 7.23h12v-.646q0-.332-.214-.625q-.215-.292-.593-.494q-1.235-.598-2.546-.916q-1.312-.319-2.647-.319t-2.646.319q-1.311.318-2.546.916q-.379.202-.593.494q-.215.293-.215.625v.646Zm6-9.23Zm0 9.23Z" />
                      </svg>
                    </button>
                    <button className='unstyled-button' onClick={() => setSearchBarVisible(!isSearchBarVisible)}>
                      {isSearchBarVisible
                        ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
                          <g fill="none" stroke="#000" strokelinecap="round" strokelinejoin="round" stroke-width="4">
                            <path d="M8 8L40 40" />
                            <path d="M8 40L40 8" />
                          </g>
                        </svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                          <path fill="currentColor" d="m11.271 11.978l3.872 3.873a.502.502 0 0 0 .708 0a.502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532c-2.73-2.73-7.17-2.73-9.898 0c-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05a.5.5 0 0 0 0-1a5.96 5.96 0 0 1-4.242-1.757a6.01 6.01 0 0 1 0-8.486a6.004 6.004 0 0 1 8.484 0a6.01 6.01 0 0 1 0 8.486a.5.5 0 0 0 .034.738" />
                        </svg>
                      }

                    </button>
                  </div>
                </div>
                <div className="search_bar" style={{ opacity: isSearchBarVisible ? 1 : 0 }}>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  <svg width="20" height="21" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.25 14.9342C11.5637 14.9342 14.25 12.2479 14.25 8.9342C14.25 5.6205 11.5637 2.9342 8.25 2.9342C4.93629 2.9342 2.25 5.6205 2.25 8.9342C2.25 12.2479 4.93629 14.9342 8.25 14.9342Z"
                      stroke="#939393"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.75 16.4342L12.4875 13.1717"
                      stroke="#939393"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* <div className="groupChat_btn">
                  <button className='mainButton' onClick={showModal}> Create Group</button>
                </div> */}
              </div>
              <UserList
                propdata={(propdata)}
                searchname={searchText}
                onlineUsers={onlineUsers}
                getsortinguserids={getsortinguserids}
                getsortinggroupids={getsortinggroupids}
                getgroupinfoupdate={getgroupinfoupdate}
                Setgroupinfoupdate={Setgroupinfoupdate}
                select_user_id={getUserId?.user_id}
                recipient={recipient}
                setRecipient={setRecipient}
                getgroupmsg={getgroupmsg}
                setgroupmsg={setgroupmsg}
                isModalOpen={isModalOpen}
                Getgropelist={Getgropelist}
                setGetgropelist={setGetgropelist}
                getgroupList={getgroupList}
              />
            </div>
          </div>
          <div style={{ width: "50%", flexGrow: "1" }}>
            <div className="chat__wrapper">

              {getUserId
                ? <div className="userProfile">
                  <div className="profile" onClick={openDrawer1}>
                    {getUserId?.user_id
                      ?
                      // ((getUserId?.file_name) && (getUserId?.file_path) && (getUserId?.user_id)) ?
                      //   <img src={`${docurlchat}${getUserId?.file_path}/${getUserId?.file_name}`} alt='' />
                      //   :
                      <Avatar size="large" icon={<UserOutlined />} />
                      :
                      ((getUserId?.file) && (getUserId?.group_id))
                        ?
                        <img src={`${docurlchat}${getUserId?.file}`} alt='' />
                        :
                        <Avatar size="large" icon={<UserOutlined />} />
                    }
                    <div className="name" style={{ textAlign: 'left' }}>
                      {(getUserId?.group_name) ? getUserId?.group_name : getUserId?.username}
                      <div style={{ color: (exists_online_offline) ? 'green' : 'black' }} className='text_align_left'>
                        {getUserId?.group_name ? '' : (exists_online_offline ? (Gettypinguser?.from_user_id === getUserId?.user_id) ? 'Typing...' : 'Online' : 'Offline')}
                      </div>
                    </div>
                  </div>
                  {/* {(getUserId.user_id) ?
                  <div className="action_btn">
                    <img src={PhoneCall} className='videoCall_icon' />
                    <img src={VideoCall} className='videoCall_icon' />
                  </div>
                  : ""
                } */}
                </div>
                : <></>
              }

              {/* <div className="date_frameChat">
                <span>Yesterday</span>
              </div> */}
              {
                getUserId?.user_id ? (
                  <Chats propdata={{ propdata: getUserId, probschatreceive: recipient, getidsFortick: getidsFortick, SetidsFortick: SetidsFortick, setSentMessageSuccess: setSentMessageSuccess, sentMessageSuccess: sentMessageSuccess }} />
                ) : getUserId?.group_id ? (
                  <Chatsgroup propdata={{ propdata: getUserId, probschatreceive_group_msg: getgroupmsg }} />
                ) : (

                  <AnotherComponent />
                )
              }
              {/* {(getUserId.user_id) ?
                < Chats propdata={{ propdata: getUserId, probschatreceive: recipient }} /> :
                <Chatsgroup propdata={{ propdata: getUserId, probschatreceive_group_msg: getgroupmsg }} />
              } */}
              {/* <YourComponent /> */}

              {getUserId
                ? <div className="mesg__typer">
                  <label htmlFor="fileInput" >
                    {((getUserId?.user_id) || (getUserId?.group_id))
                      ? <div className='d-flex gap-2 align-items-center'>
                        <input type="file" ref={fileInputRef} key={inputKey} style={{ display: 'none' }} onChange={(event) => handleButtonClickuploadfile(event)} />
                        <button type="button" className="uploadFileIcon p-2" onClick={handleButtonClick}  >
                          {/* <img src={attach} alt="Attach" /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24" cursor={"pointer"} >
                            <path fill="none" stroke="currentColor" stroke-width="2" d="m22 12l-9 9c-6 6-15-3-9-9l9-9c4-4 10 2 6 6l-9 9c-2 2-5-1-3-3l9-9" />
                          </svg>
                        </button>
                        <button type="button" className='uploadFileIcon p-2' >
                          <label htmlFor="file-upload" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 24 24" cursor={"pointer"}>
                              <path fill="currentColor" d="M19 13a1 1 0 0 0-1 1v.38l-1.48-1.48a2.79 2.79 0 0 0.93 0l-.7.7l-2.48-2.48a2.85 2.85 0 0 0-3.93 0L4 12.6V7a1 1 0 0 1 1-1h7a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-5a1 1 0 0 0-1-1ZM5 20a1 1 0 0 1-1-1v-3.57l2.9-2.9a.79.79 0 0 1 1.09 0l3.17 3.17l4.3 4.3Zm13-1a.89.89 0 0 1-.18.53L13.31 15l.7-.7a.77.77 0 0 1 1.1 0L18 17.21Zm4.71-14.71l-3-3a1 1 0 0 0-.33-.21a1 1 0 0 0-.76 0a1 1 0 0 0-.33.21l-3 3a1 1 0 0 0 1.42 1.42L18 4.41V10a1 1 0 0 0 2 0V4.41l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z" />
                            </svg>
                          </label>
                          <input id="file-upload" type="file" accept="image/*" key={inputKey} onChange={handleButtonClickuploadImage} multiple />
                        </button>
                      </div>
                      : ""
                    }
                  </label>
                  {((getUserId?.user_id) || (getUserId?.group_id)) ?
                    <input type="text" placeholder="Type a Message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent the default behavior (e.g., form submission)
                        sendMessage();
                      }
                    }} />
                    : ""
                  }

                  {(getselectedFilesSingleChat.length > 0) ? (
                    getselectedFilesSingleChat.map((file, index) => {
                      return (
                        <div key={index}>
                          <Button type="text" onClick={() => cancelFile(index)} />
                          {!hide ?
                            <div className='show_file_box'>
                              {/* <CloseOutlined onClick={() => setHide(true)} />
                          <Tag closable >
                            {getselectedFilesSingleChat ?
                              <>
                                <img src={preview ? preview : profileImageAuth + getselectedFilesSingleChat} style={{ objectFit: 'contain' }} alt='' />
                                {file?.name}
                              </>
                              :
                              null
                            }

                          </Tag> */}
                              {/* <div className=' overflow-auto w-100 '>
                            <div className='d-flex gap-3 py-2 select-doc-container'>
                              {Array(3).fill(0).map((_, index) => (
                                <div className='select-doc-card'>
                                  <img src='https://images.unsplash.com/photo-1710009437292-77d057fd47f2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                                  <p>abhimanyu 1 ...</p>
                                  <CloseOutlined className='close-icon' />
                                </div>
                              ))}
                              {Array(2).fill(0).map((_, index) => (
                                <div className='select-doc-card'>
                                  <img src={fileImage} />
                                  <p>abhimanyu 2 ...</p>
                                  <CloseOutlined className='close-icon' />
                                </div>
                              ))}
                            </div>
                          </div> */}

                              {/* <Button type="submit" onClick={sendMessage} style={{ background: "red" }} icon={<img src={sendIcon} alt="Send" />} /> */}
                            </div>
                            : ''}
                        </div>
                      )
                    }
                    )
                  ) : (
                    // <Button type="submit" onClick={sendMessage} icon={<img src={sendIcon} alt="Send" />} />
                    <></>
                  )}

                  {((getUserId?.user_id) || (getUserId?.group_id)) ?
                    <Button type="submit" onClick={sendMessage} icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.912 12H4L2.023 4.135A.662.662 0 0 1 2 3.995c-.022-.721.772-1.221 1.46-.891L22 12L3.46 20.896c-.68.327-1.464-.159-1.46-.867a.66.66 0 0 1 .033-.186L3.5 15" />
                    </svg>} />
                    : ""
                  }

                  {/* <Button type="submit" onClick={sendMessage} icon={<img src={sendIcon} alt="Send" />} /> */}
                  <div className="uploadFile_wrapper" style={uploadFilePopup ? { bottom: 0, opacity: 1 } : { bottom: -300, opacity: 0 }}>
                    <div className="closeUploadModal" onClick={() => setUploadFilePopup(false)}>
                      <CloseOutlined onClick={() => handleRemovebtn()} />
                    </div>
                    <div className='show_file_box-2'>
                      {/* <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload> */}

                      <div className=' overflow-auto w-100 '>
                        <div className='d-flex gap-3 py-2 select-doc-container'>

                          {(getsingleChatimageUpload.length > 0) ? getsingleChatimageUpload.map((item, index) => (
                            <div key={index} className='select-doc-card'>
                              {loading && <div style={{ position: "absolute", top: "50px", left: "45px" }}> <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />} /></div>}
                              <img src={item.path} alt='Placeholder' />
                              {loading && <div>  <Progress percent={progress} /></div>}
                              {/* <p>{item.file.name}</p> */}
                              <CloseOutlined className='close-icon' onClick={() => handleRemoveimage(index)} />
                            </div>
                          )) : ""}
                          {/* {Array(3).fill(0).map((_, index) => (
                        <div className='select-doc-card'>
                          <img src='https://images.unsplash.com/photo-1710009437292-77d057fd47f2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                          <p>abhi ...</p>
                          <CloseOutlined className='close-icon' />
                        </div>
                      ))} */}
                          {(getsingleChatfileUpload.length > 0) ? getsingleChatfileUpload.map((item, index) => (
                            <div key={index} className='select-doc-card'>
                              {loading && <div style={{ position: "absolute", top: "50px", left: "45px" }}> <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />} /></div>}
                              <img src={fileImage} alt='File Icon' />
                              {loading && <div>  <Progress percent={progress} /></div>}
                              {/* <p>{item.file.name}</p> */}
                              <CloseOutlined className='close-icon' onClick={() => handleRemoveFile(index)} />
                            </div>
                          )) : ""}
                        </div>
                      </div>
                    </div>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelUpload}>
                      <img alt="example"
                        style={{
                          width: '100%',
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </div>
                : <></>
              }


            </div>
            {/* ./chat__wrapper */}

          </div>
          <div ref={drawerRef} className='profile-section-overlay' style={{ width: openProfile1 ? "360px" : "0px" }}>
            <div className="profile-section" >
              <div className="d-flex justify-content-end">
                <Button shape="circle" icon={<CloseOutlined />} type="text" size='large' onClick={drawerClose1}>

                </Button>
              </div>
              {/* <div className="btn__close">close</div> */}
              <Detail group_id={getUserId} Setgroupinfoupdate={Setgroupinfoupdate} />
            </div>
          </div>
        </div>

        {/* BackDrop */}
        {/* <div className="sidebar-overlay" onClick={handleBackdrop} style={backDrop ? { left: 0 } : null}></div> */}

      </div >
      {/* //############################ Model crete Group Start################## */}
      < Modal title="Basic Modal" open={isModalOpen} onCancel={handleCancel} footer={null} >
        <Form.Item label="Create Group">
          <Input placeholder="Create Group" value={formDataField.group_name} onChange={handlegroup_nameChange} />
        </Form.Item>
        <Form.Item label="User Assign">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={formDataField?.user_ids}
            onChange={handleUserSelectChange}>
            {getuserlist.map((item, index) => (
              item?.user &&
              (<Select.Option key={index} value={item.user_id} >
                {item?.user?.userfullname}
              </Select.Option>)
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button className='profile_submit' onClick={CreateGroup} >
            Submit
          </Button>
        </Form.Item>
      </Modal >
      {/* //############################ Model crete Group End################## */}
      <ToastContainer />
    </>

  );
}

export default ChatGrid;