// @ts-nocheck
import { DeleteOutlined } from "@mui/icons-material";
import userimg from "../../assests/img/user.jpg"
import React, { useEffect, useState } from "react";
import { docurlchat } from "../../utils/configurable";
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFetch } from "../../Services/useFetch";
const GroupMember = (group_data) => {
    const { userData } = useSelector((state) => state.loginData)
    const notify = (error) => toast.error(error);
    const [groupUsers, setGroupUsers] = useState([]);
    const notifySuccess = (msg) => toast.success(msg);
    const [visible, setVisible] = useState(false);
    const [getuserid, setuserid] = useState(false);
    const handleButtonClick = (data) => {
        setuserid(data);
        setVisible(true);

    }
    const { request } = useFetch()
    useEffect(() => {
        setGroupUsers(group_data?.group_data?.group_user)
    }, [group_data?.group_data?.group_user])

    const handleRemoveUser = (userIdToRemove) => {
        const updatedUsers = groupUsers.filter(user => user?.user_id !== userIdToRemove);

        setGroupUsers(updatedUsers);
    };
    const handleCloseModel = () => {
        setVisible(false);
    }
    const handleDeleteTender = async () => {

        const formData = new URLSearchParams();
        formData.append('user_id', getuserid);
        formData.append('group_id', group_data?.group_data?.group_info?.id);
        const response = await request('/delete-user-in-group', 'delete', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
        if (response?.data?.status === '1') {
            notifySuccess('User Delete Successfully done')
            setVisible(false);
            handleRemoveUser(getuserid)
        } else {
            notify('Something Went Wrong')
        }
    }
    const deisgn = (
        <>
            {/* <div className="group-member-list">
                <img src={userimg} alt="" />
                <div className="user-details">
                    <h3 className='user-heading'>user</h3>
                    <span>group admin</span>
                </div>

            </div> */}
            {groupUsers?.map((item, index) => (
                <div className="group-member-list" key={index}>
                    {(item?.user?.profileimg) && (item?.user?.profileimg_path) ?
                        <img src={docurlchat + item?.user?.profileimg_path + '/' + item?.user?.profileimg} alt="" />
                        :
                        <img src={userimg} alt="sds" />
                    }
                    <div className="user-details">
                        <h3 className='user-heading'>{item?.user?.userfullname + ' (' + item?.user?.email + ')'}</h3>
                        {/* <span>group admin</span> */}
                        {(userData?.data?.id === group_data?.group_data?.group_info?.created_by) ?
                            <DeleteOutlined onClick={() => handleButtonClick(item?.user_id)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                            : ""
                        }
                    </div>

                </div>
            ))}

            <Modal
                className="bd_delete_model"
                open={visible}
                onCancel={handleCloseModel}
                footer={[
                    <button className='BG_ghostButton' key="cancel" onClick={handleCloseModel} >
                        Cancel
                    </button>,
                    <button className='BG_deleteButton' key="delete"
                        onClick={handleDeleteTender}
                    >
                        Delete
                    </button>
                ]}
            >
                <div className='bd_delete_container'>
                    <span className='bd_delete_spn'>Are you sure you want to delete this ?</span>
                    {/* <p>If you delete the User, you can't recover it.</p> */}
                </div>
            </Modal>
        </>
    );
    return deisgn;
}
export default GroupMember;