// @ts-nocheck


const CHATGRIDROUTES = {

    LOGIN: '/login',
    PROFILE: "/profile",
    CHATGRID: "/chatgrid",
    SETTINGS: '/setting',
    NOTIFICATION: "/notification",
    ADMIN: "/admin",
    ERROR: '/error'

}
export default CHATGRIDROUTES;