
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    NEW_APP_LAUNCH
} from "../constants/authConstant";
// const initialState = {
//     userData: {},
// };

const initialState = {
    userData: {},
    loading: false,
    error: null,
};

export const userDataReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case LOGIN_REQUEST:
            return { ...state, loading: true, error: null };

        case LOGIN_SUCCESS:
            console.log("successs data")
            if (state.userData === payload) return state;
            return { ...state, userData: payload, loading: false, error: null };

        case LOGIN_FAILED:
            return { ...state, loading: false, error: payload };

        case LOGOUT:
            return { ...initialState };

        case NEW_APP_LAUNCH:
            return { ...initialState };

        default:
            return state;
    }
};
