// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Row, Col, Form, Select, DatePicker, Input, Tabs, Button, Progress } from 'antd';
import { Down } from '@icon-park/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import dayjs from 'dayjs';
import PasswordChangeBids from './PasswordChange';
import DefaultImage from '../../assests/img/user.jpg'
import { useDispatch } from 'react-redux';
import { userInfoAction } from '../../Redux/actions/userInfoAction';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../chatRoutes/chatRoutes';
import { useParams } from 'react-router-dom';
import { docurlchat } from '../../utils/configurable';
import { REMARKS_STRINGS } from '../../Constant/Strings';
import { useFetch } from '../../Services/useFetch';
const { TextArea } = Input;

const intialState = {
    firstname: '',
    lastname: '',
    role_id: null,
    company_id: null,
    department_id: null,
    designation_id: null,
    jobgrade_id: null,
    reporting_mngr_id: null,
    official_email: '',
    email: '',
    contactnumber: '',
    unit_id: null,
    country_id: null,
    state_id: null,
    city_id: null,
    date_of_birth: null,
    full_address_1: '',
    zip_code: '',
    old_password: '',
    password: '',
    confirmPassword: "",
    isactive: null
}
const optionVal = {
    // role: [],
    // unit: [],
    // department: [],
    designation: [],
    // company: [],
    // jobgrid: [],
    reporting_manager: []

}
const notify = (error) => toast.error(error);
const notifySuccess = (msg) => toast.success(msg);

const CustomProfile = (props) => {
    const { isPersonal } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { socket, isConnected } = useSelector((state) => state.socket);
    const { request } = useFetch()
    const { id } = useParams();
    const [form] = Form.useForm();
    const [formdata] = Form.useForm();
    const { userData } = useSelector((state) => state.loginData)
    // const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)
    // const ChatCountry = null
    const { userInfo } = useSelector((state) => state.userDetails)

    const [dropdownData, setDropdownData] = useState(optionVal)
    const [skeleton, setSkeleton] = useState(false)
    const [personalInputData, setPersonalInputData] = useState(intialState)
    const [profileState, setProfileState] = useState([])
    const [profileCity, setProfileCity] = useState([])
    const [userDetails, setUserDetails] = useState([]);
    const [storageDetail, setStorageDetail] = useState({});
    const [getProgressPercentage, setProgressPercentage] = useState(null);
    const [tabChange, setTabChange] = useState('1')
    const [ChatCountry, setChatCountry] = useState([])
    const [getDesignationDetails, setDesignationDetails] = useState([])

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const getCountryList = async () => {
        try {
            const response = await request('/country-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == 1) {
                setChatCountry(response?.data?.data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    }

    useEffect(() => {
        getCountryList()
    }, [])

    const BdUserdetails = async () => {
        const userId = localStorage?.getItem('user_id')
        const formData = new URLSearchParams();
        formData.append('user_id', userId)
        try {
            const response = await request('/user-details-edit', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == 1) {
                setUserDetails(response.data.data)
                if (isPersonal) {
                    dispatch(userInfoAction({
                        ...response.data.data,
                        docname: userInfo.docname,
                        docpath: userInfo.docpath,
                        thumbnailName: userInfo.thumbnailName,
                        thumbnailPath: userInfo.thumbnailPath,
                        profileimg: response.data.data?.profileimg,
                        profileimg_path: response.data.data?.profileimg_path
                    }));
                }
            }
            else {
                setUserDetails([])
                notify(response?.response?.data?.message)
            }
        } catch (error) {
            console.log(error, 'api error');
        }
    };

    const getDesignationFun = async () => {
        const formData = new URLSearchParams();
        formData.append('designation_id', userInfo?.designation_id)
        try {
            const response = await request('/designation-edit', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == 1) {
                setDesignationDetails(response?.data?.data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    }

    useEffect(() => {
        getDesignationFun()
    }, [userInfo?.designation_id])

    // const empDropdownValues = async () => {
    //     try {
    //         const [
    //             // roleResponse,
    //             // unitResponse,
    //             // departmentResponse,
    //             designationResponse,
    //             // companyResponse,
    //             // jobGridResponse,
    //             reportingManagerResponse
    //         ] = await Promise.all([
    //             // request('/role-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             // request('/businessunit-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             // request('/department-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             request('/designation-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             // request('/company-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             // request('/jobgrade-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" }),
    //             request('/users-list-all', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
    //         ]);
    //         setDropdownData(prev => ({
    //             ...prev,
    //             // role: roleResponse?.data?.data,
    //             // unit: unitResponse?.data?.data,
    //             // department: departmentResponse?.data?.data,
    //             designation: designationResponse?.data?.data,
    //             // company: companyResponse?.data?.data,
    //             // jobgrid: jobGridResponse?.data?.data,
    //             reporting_manager: reportingManagerResponse?.data?.data
    //         }));
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const userUpdatePersonal = async (value) => {
        try {
            const formdata = new URLSearchParams();
            formdata.append('update_user_id', isPersonal ? userDetails?.id : id)
            formdata.append('country_id', value?.country_id)
            formdata.append('state_id', value?.state_id)
            formdata.append('city_id', value?.city_id)
            formdata.append('full_address_1', value?.full_address_1)
            formdata.append('zip_code', value?.zip_code)
            formdata.append('date_of_birth', value?.date_of_birth !== null ? dayjs(value?.date_of_birth)?.format('YYYY-MM-DD') : '')
            const response = await request('/personal-profile-update', 'put', formdata, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status == 1) {
                BdUserdetails()
                notifySuccess(response?.data?.message)
                handleResetOfficial()
                if (isConnected) {
                    socket?.emit(`user_update`, { user_id: isPersonal ? userDetails?.id : id, remarks: REMARKS_STRINGS.USER_PROFILE_UPDATE })
                }
            }
            else {
                notify(response?.response?.data?.message);
            }
        } catch (error) {
            console.log(error, 'Api Error');

        }
    }
    console.log(getDesignationDetails, "getdesignation")

    const userUpdateOfficial = async (value) => {
        try {
            const formdata = new URLSearchParams();
            formdata.append('update_user_id', isPersonal ? userDetails?.id : id)
            formdata.append('firstname', value?.firstname || '')
            formdata.append('lastname', value?.lastname || '')
            formdata.append('contactnumber', value?.contactnumber || '')
            formdata.append('emp_role_id', value?.role_id || '')
            formdata.append('company_id', value?.company_id !== null ? value?.company_id : '')
            formdata.append('department_id', value?.department_id !== null ? value?.department_id : '')
            formdata.append('designation_id', value?.designation_id || '')
            formdata.append('jobgrade_id', value?.jobgrade_id !== null ? value?.jobgrade_id : '')
            formdata.append('reporting_mngr_id', value?.reporting_mngr_id !== null ? value?.reporting_mngr_id : '')
            formdata.append('business_unit_id', value?.unit_id !== null ? value?.unit_id : '')
            formdata.append('email', value?.email)
            formdata.append('official_email', value?.official_email)
            formdata.append('isactive', value?.isactive) // will need to make it dynamic
            // formdata.append('system_admin', 2)
            const response = await request('/official-profile-update', 'put', formdata, { 'Content-Type': "application/x-www-form-urlencoded" })

            if (response?.data?.status == 1) {
                BdUserdetails()
                notifySuccess(response?.data?.message)
                handleResetPersonal()
                if (isConnected) {
                    socket?.emit(`user_update`, { user_id: isPersonal ? userDetails?.id : id, remarks: REMARKS_STRINGS.USER_PROFILE_UPDATE })
                }
            }
            else {
                notify(response?.response?.data?.message);
            }
        } catch (error) {
            console.log(error, 'Api Error');
        }
    }

    // const fetchStorageDetail = async () => {
    //     try {
    //         const response = await request('/get-storage-details', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
    //         if (response?.data?.status == 1) {
    //             setStorageDetail(response?.data?.data);
    //             let storage = response?.data?.data;
    //             let progressPercentage = (storage?.UsedSpace / storage?.TotalLimit) * 100;
    //             setProgressPercentage(progressPercentage)

    //         }
    //         else {
    //             setStorageDetail({});
    //             notify(response?.response?.data?.message);
    //         }
    //     } catch (error) {
    //         console.log(error, 'Api Error');
    //     }
    // }




    useEffect(() => {
        BdUserdetails();
        // fetchStorageDetail();

    }, [])

    // useEffect(() => {
    //     empDropdownValues()
    // }, [])


    // passord api --- khushbu
    const postPasswordChange = async (handleResetChangePassword) => {

        const { password, confirmPassword, old_password } = personalInputData;
        try {

            const formData = new URLSearchParams();
            formData.append('user_id', userInfo?.id)
            formData.append('old_password', old_password);
            const response = await request('/check-old-password', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response.data.status === '1') {
                if (password === confirmPassword) {
                    const formData = new URLSearchParams();
                    formData.append('user_id', userInfo?.id)
                    formData.append('password', password);
                    const response = await request('/change-password', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
                    if (response.data.status === '1') {
                        setPersonalInputData(response?.data?.message);
                        notifySuccess(response?.data?.message)
                        handleResetOfficial()
                        handleResetChangePassword()
                        setTimeout(() => {
                            navigate(ROUTES.BD_LOGIN)
                            localStorage.setItem('bidToken', '');
                        }, [1000])
                    }
                    else {
                        notify(response?.response?.data?.message);
                    }
                }
                else {
                    notify('Passwords do not match or do not meet criteria');
                }
            }
            else {
                console.log(response, 'response')
                notify("Old Password is incorrect!!");
            }
        } catch (error) {
            notify("Old Password is incorrect!!");
            console.log("Server error")
        }
    }

    // common functions


    const getUserStateLoc = async () => {
        const formData = new URLSearchParams();
        formData.append('country_id', personalInputData?.country_id);
        try {
            const response = await request('/state-list', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" });
            if (response?.data?.status === '1') {
                setProfileState(response?.data?.data)
            } else {
                setProfileState([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }

    const getUserCityLoc = async () => {
        const formData = new URLSearchParams();
        formData.append('state_id', personalInputData?.state_id);
        try {
            const response = await request('/city-list', 'post', formData, { 'Content-Type': "application/x-www-form-urlencoded" })
            if (response?.data?.status === '1') {
                setProfileCity(response?.data?.data)
            } else {
                setProfileCity([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }
    useEffect(() => {
        if (personalInputData?.country_id) {
            getUserStateLoc()
        }
    }, [personalInputData?.country_id])

    useEffect(() => {
        if (personalInputData?.state_id) {
            getUserCityLoc()
        }
    }, [personalInputData?.state_id])

    const handleResetOfficial = () => {
        setPersonalInputData('');
        form.resetFields()
    };
    const handleResetPersonal = () => {
        // setPersonalInputData('');
        formdata?.resetFields()
    };

    const handleInputChange = (name, value, date) => {
        if (name === "country_id") {
            formdata?.setFieldsValue({
                state_id: null,
                city_id: null
            })
        }
        if (name === "state_id") {
            formdata?.setFieldsValue({
                city_id: null
            })
        }
        setPersonalInputData(
            {
                ...personalInputData,
                [name]: value
            })
    }

    const predefinedvalues = () => {
        const officialObj = {
            firstname: userDetails?.firstname || '',
            lastname: userDetails?.lastname || '',
            role_id: dropdownData?.role?.find(val => val?.id === userDetails?.bg_mstr_role?.id)?.hasOwnProperty('id') ? userDetails?.bg_mstr_role?.id : null,
            company_id: dropdownData?.company?.find(val => val?.id === userDetails?.company_id)?.hasOwnProperty('id') ? userDetails?.company_id : null,
            department_id: dropdownData?.department?.find(val => val?.id === userDetails?.department_id)?.hasOwnProperty('id') ? userDetails?.department_id : null,
            designation_id: dropdownData?.designation?.find(val => val?.id === userDetails?.designation_id)?.hasOwnProperty('id') ? userDetails?.designation_id : null,
            jobgrade_id: dropdownData?.jobgrid?.find(val => val?.id === userDetails?.jobgrade_id)?.hasOwnProperty('id') ? userDetails?.jobgrade_id : null,
            reporting_mngr_id: dropdownData?.reporting_manager?.find(val => val?.id === userDetails?.reporting_mngr_id)?.hasOwnProperty('id') ? userDetails?.reporting_mngr_id : null,
            email: userDetails?.email || '',
            official_email: userDetails?.official_email || '',
            contactnumber: userDetails?.contactnumber || '',
            unit_id: dropdownData?.unit?.find(val => val?.id === userDetails?.unit_id)?.hasOwnProperty('id') ? userDetails?.unit_id : null,
            isactive: userDetails?.isactive
        }

        const personalObj = {
            country_id: userDetails?.country_id || '',
            state_id: userDetails?.state_id || '',
            city_id: userDetails?.city_id || '',
            // state_id: profileState?.find(val => val?.id === userDetails?.state_id) ? userDetails?.state_id : null,
            // city_id: profileCity?.find(val => val.id === userDetails?.city_id) ? userDetails?.city_id : null,
            date_of_birth: userDetails?.date_of_birth !== null ? dayjs(userDetails?.date_of_birth) : null,
            full_address_1: userDetails?.full_address_1 || '',
            zip_code: userDetails?.zip_code,
            permanent_sys_adm: userDetails?.permanent_sys_adm
        }

        setPersonalInputData((prevState) => {
            return {
                ...prevState,
                ...officialObj,
                ...personalObj
            };
        });
        form.setFieldsValue(officialObj);
        formdata.setFieldsValue(personalObj);
    }


    useEffect(() => {
        predefinedvalues()

    }, [userDetails, dropdownData])


    const validateContactNumber = (_, value) => {
        // Regular expression to match exactly 10 digits, not starting with 0
        const regex = /^[1-9][0-9]{9}$/;

        if (!value || regex.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject('Contact number must be a 10-digit number starting with a digit from 1 to 9');
    };

    const handleKeyPressForMobile = (e) => {
        const key = e.key;
        const value = e.target.value;
        const cursorPosition = e.target.selectionStart;

        // Allow navigation keys and backspace
        if (key === "Backspace" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight") {
            return;
        }

        // Prevent entering non-numeric keys
        if (!(key >= "0" && key <= "9")) {
            e.preventDefault();
            return;
        }

        // Prevent starting with 0
        if (cursorPosition === 0 && key === "0") {
            e.preventDefault();
            return;
        }

        // Prevent inserting 0 at the beginning after deleting some digits
        const newValue = value.slice(0, cursorPosition) + key + value.slice(cursorPosition);
        if (newValue.startsWith("0")) {
            e.preventDefault();
            return;
        }
    };





    const handleProfileChange = (name, e) => {
        const trimmedValue = e.target.value.trimStart(); // Trim leading spaces
        form.setFieldsValue({ [name]: trimmedValue }); //
    };

    const updateProfilePicture = async (file) => {
        const formData = new FormData();
        formData.append('user_id', userData?.data?.id)
        formData.append('files', file);
        try {
            const response = await request('/user-profile-pic-update', 'post', formData, { 'Content-Type': "multipart/form-data" })
            if (response?.data?.status === '1') {
                BdUserdetails()
                notifySuccess('Logo updated successfully');
            } else {
                notify(response?.response?.data?.message);
            }
        } catch (error) {
            console.error('API Error:', error);
            notify('Error updating logo');
        }
    }

    function handleImageChange(event) {
        const file = event.target.files[0];
        updateProfilePicture(file)
    }
    // to edit employee info functions


    const handleTabChange = (key) => {
        setTabChange(key)
    }

    return (
        <>
            <div className="bd_profile_main">
                {!isPersonal && <div className='bd_main_profile_headings'>Employee Profile</div>}

                <div className='bd_main_add'>
                    <div className='bd_main_sub_add_profile'>
                        <div className='bd_main_sub_image'>
                            <img className="bd_main_sub_s_img" src={userDetails?.profileimg !== null ? `${docurlchat}${userDetails.profileimg_path}/${userDetails.profileimg}` : DefaultImage} alt="user" />
                            <label className='bd_main_update_profile_btn'>
                                Update Image
                                <input
                                    className='input_update_file'
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </label>
                        </div>
                        <div className="line_through"></div>
                        <div className='bd_main_sub_info'>
                            {skeleton ?
                                <>

                                    <div className='sub_emp_id'>
                                        <Skeleton variant="rectangular" width={120} height={25} />
                                        <Skeleton variant="rectangular" width={100} height={25} />
                                    </div>
                                    <div className='sub_contact'>
                                        <p className='sub_pera'> <span className='sub_span'>Designation: </span> <Skeleton variant="rectangular" width={150} height={23} /></p>
                                        <p className='sub_pera'> <span className='sub_span'>Email:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                                        <p className='sub_pera'> <span className='sub_span'>Contact:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='sub_emp_id'>
                                        <span className='sub_emp_head'>{userDetails.userfullname}</span>
                                        {/* <span className='sub_id'>{dropdownData?.role?.find(item => item?.role_id === userDetails?.role_id)?.role_name}</span> */}
                                    </div>
                                    <div className='sub_contact'>
                                        <p className='sub_pera'> <span className='sub_span'>Designation: </span>{getDesignationDetails?.designation_name}</p>
                                        <p className='sub_pera'> <span className='sub_span'>Email:</span> {userDetails?.email}</p>
                                        <p className='sub_pera'> <span className='sub_span'>Contact:</span>{userDetails?.contactnumber} </p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

                {/* <div className="storage_wrapper">
                    <div className="storage_stats">
                        <Progress percent={getProgressPercentage} showInfo={false} />
                        <h5>3.68 GB of 15 GB used</h5>
                        <h5>{`${storageDetail?.UsedSpace} GB of ${storageDetail?.TotalLimit} GB used`}</h5>
                    </div>
                    <button className="BG_ghostButton upgrade_btn">Upgrade plan</button>
                </div> */}

                {/* <--------- tabs -------> */}
                <div className='bd_main_profile_tabs'>
                    <div className='profile_tabs_heading'>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: 'Official',
                                    key: '1',
                                    children: <div className='profile_detail_info_box'>
                                        <Form
                                            form={form} name="control-hooks" layout="vertical" autoComplete="off" onFinish={userUpdateOfficial}
                                            layout="vertical"
                                        >
                                            <Row gutter={20}>
                                                <Col sm={12} md={6} xs={24}>
                                                    <Form.Item label="First Name:" name="firstname" rules={[{ required: true, message: 'First name is required' }]}>
                                                        <Input
                                                            placeholder="Enter here"
                                                            disabled={isPersonal && userDetails?.permanent_sys_adm !== '1'}
                                                            value={personalInputData?.firstname}
                                                            onChange={(e) => handleProfileChange('firstname', e)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={12} md={6} xs={24}>
                                                    <Form.Item label="Last Name:" name='lastname' rules={[{ required: true, message: 'Last name is required' }]}>
                                                        <Input
                                                            value={personalInputData?.lastname}
                                                            placeholder="Enter here"
                                                            onChange={(e) => handleProfileChange('lastname', e)}
                                                            disabled={isPersonal && userDetails?.permanent_sys_adm !== '1'}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col sm={12} md={6} xs={24}>
                                                    <Form.Item label="Email :" name='email' rules={[{ required: true, message: 'Email is required' }]}>
                                                        <Input
                                                            value={personalInputData?.email}
                                                            placeholder="Enter here"
                                                            onChange={(e) => handleProfileChange('email', e)}
                                                            disabled={isPersonal && userDetails?.permanent_sys_adm !== '1'}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col sm={12} md={6} xs={24}>
                                                    <Form.Item label="Contact Number:" name='contactnumber' rules={[
                                                        { required: true, message: 'Contact number is required' },
                                                        {
                                                            pattern: /^[0-9]{10}$/, // Validate for a 10-digit number
                                                            message: 'Please enter a valid contact number',
                                                        },
                                                    ]}>
                                                        <Input
                                                            placeholder="Enter here"
                                                            type='text'
                                                            maxLength={10}
                                                            onKeyDown={handleKeyPressForMobile}
                                                            onChange={(e) => handleProfileChange('contactnumber', e)}
                                                            value={personalInputData?.contactnumber}
                                                            disabled={isPersonal && userDetails?.permanent_sys_adm !== '1'}

                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {((isPersonal && userDetails?.permanent_sys_adm === '1') || !isPersonal) && <div className='profile_button_sec'>
                                                <Button className='BG_ghostButton' onClick={handleResetOfficial}>Reset</Button>
                                                {/* <Button className='BG_mainBtton'>Save & Change</Button> */}
                                                <button className='BG_mainButton' key="submit" >Save & Change</button>

                                            </div>}
                                        </Form>
                                    </div>,
                                },
                                {
                                    label: 'Personal',
                                    key: '2',
                                    children: <div className='profile_detail_info_box'>
                                        <div className='profile_sub_info'>
                                            <Form
                                                form={formdata} name="control-hooks" layout="vertical" autoComplete="off" onFinish={userUpdatePersonal}
                                            >
                                                <Row gutter={20}>
                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item label="DOB"
                                                            allowClear
                                                            name='date_of_birth'
                                                            rules={[{ required: true, message: 'DOB is required' }]}>
                                                            <DatePicker
                                                                placeholder="Date of birth"
                                                                value={personalInputData?.date_of_birth}
                                                                onChange={(value) => handleInputChange('date_of_birth', value)}
                                                                disabledDate={disabledDate}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item
                                                            allowClear
                                                            label="Country"
                                                            rules={[{ required: true, message: 'Country is required' }]}
                                                            name='country_id'
                                                        >
                                                            <Select
                                                                allowClear
                                                                showSearch
                                                                value={personalInputData?.country_id}
                                                                onChange={(value) => handleInputChange('country_id', value)}
                                                                placeholder="Select Country"
                                                                options={ChatCountry?.map((item, index) => {
                                                                    return {
                                                                        value: item?.id,
                                                                        label: item?.country_name
                                                                    }
                                                                })}
                                                                suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item label="State "
                                                            rules={[{ required: true, message: 'State is required' }]}
                                                            name='state_id'
                                                        >
                                                            <Select
                                                                allowClear
                                                                showSearch
                                                                value={personalInputData?.state_id}
                                                                onChange={(value) => handleInputChange('state_id', value)}
                                                                options={profileState?.map((item, index) => {
                                                                    return {
                                                                        value: item?.id,
                                                                        label: item?.state_name
                                                                    }
                                                                })}
                                                                placeholder="Select State"
                                                                suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item label="City / District"
                                                            rules={[{ required: true, message: 'City is required' }]}
                                                            name='city_id'
                                                        >
                                                            <Select
                                                                allowClear
                                                                showSearch
                                                                value={personalInputData?.city_id}
                                                                onChange={(value) => handleInputChange('city_id', value)}
                                                                options={profileCity?.map((item, index) => {
                                                                    return {
                                                                        value: item?.id,
                                                                        label: item?.city_name
                                                                    }
                                                                })}
                                                                // name='city_id'
                                                                placeholder="Select City"
                                                                suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            />

                                                        </Form.Item>
                                                    </Col>

                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item label="Zip Code"
                                                            name='zip_code'
                                                            rules={[
                                                                { required: true, message: 'Zip Code is required' },
                                                                {
                                                                    min: 6,
                                                                    message: 'Zip Code must be at least 6 characters long'
                                                                },

                                                            ]}
                                                        >
                                                            <Input
                                                                // name='zip_code'
                                                                placeholder="Zip Code"
                                                                value={personalInputData?.zip_code}
                                                                onChange={(e) => handleInputChange('zip_code', e.target.value)}
                                                                onKeyPress={(e) => {
                                                                    // Prevent the default action for non-numeric keys
                                                                    if (isNaN(Number(e?.key))) {
                                                                        e.preventDefault();
                                                                    }

                                                                    // Prevent entering more than 6 digits
                                                                    const { value } = e?.target;
                                                                    if (value?.length >= 6) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col sm={12} md={6} xs={24}>
                                                        <Form.Item label="Full Address"
                                                            rules={[{ required: true, message: 'Address is required' }]}
                                                            name='full_address_1'
                                                        >
                                                            <TextArea
                                                                // name='full_address_1'
                                                                placeholder="Full address"
                                                                value={personalInputData?.full_address_1}
                                                                onChange={(e) => handleInputChange('full_address_1', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row >
                                                <div className='profile_button_sec'>
                                                    <Button className='BG_ghostButton' onClick={handleResetPersonal}>Reset</Button>
                                                    <button className='BG_mainButton' >Save & Change</button>
                                                </div>
                                            </Form >
                                        </div >

                                    </div >
                                },
                                {
                                    label: isPersonal && 'Change Password',
                                    key: '3',
                                    children: isPersonal &&
                                        <PasswordChangeBids
                                            form={form}
                                            personalInputData={personalInputData}
                                            handleInputChange={handleInputChange}
                                            handleReset={handleResetOfficial}
                                            postPasswordChange={postPasswordChange}
                                            setPersonalInputData={setPersonalInputData}
                                        />

                                },


                            ]}
                            onChange={handleTabChange}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default CustomProfile