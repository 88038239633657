import React, { useEffect } from 'react';
import { NotifivcatioPush } from '../../firebase';

// export const Notification = () => {
//     requestForToken();
//     //....
// }
export const Notification = () => {

    NotifivcatioPush.requestForToken();
    // You might need to add dependencies or conditions for the useEffect hook based on your use case


    // Rest of your component logic here


}
