// @ts-nocheck
// import React from 'react'
import { Left } from "@icon-park/react";
import profile from "../../assests/img/profile.jpg"
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Collapse, Divider, Avatar, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { docurlchat } from "../../utils/configurable";
import { useFetch } from "../../Services/useFetch";
const UserList = (props) => {
  const { propdata, Getgropelist, setGetgropelist, getgroupList, searchname, onlineUsers, getsortinguserids, getsortinggroupids, getgroupinfoupdate, Setgroupinfoupdate, select_user_id, recipient, setRecipient, getgroupmsg, setgroupmsg } = props
  const { userData } = useSelector((state) => state.loginData)
  const [getuserlistData, setuserListData] = useState([])
  // const [Getgropelist, setGetgropelist] = useState([])
  const [Gettemraryuserids, settemraryuserids] = useState([])
  const { request } = useFetch()
  const [getgroupid, setgroupid] = useState("")
  const getUserList = async () => {
    try {
      const response = await request('/chat-user-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
      if (response?.data?.status == "1") {
        setuserListData(response?.data?.data)
      } else {
        setuserListData([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  useEffect(() => {
    getUserList()
  }, [])

  useEffect(() => {
    getUserList()
  }, [getsortinguserids])


  // const getgroupList = async () => {
  //   try {
  //     const response = await request('/user-chatgroupmember-list', 'get', null, { 'Content-Type': "application/x-www-form-urlencoded" })
  //     if (response?.data?.status == "1") {
  //       // ...response?.data?.data]
  //       setGetgropelist(response?.data?.data)
  //     } else {
  //       setGetgropelist([])
  //     }
  //   } catch (error) {
  //     console.log(error, 'api erorr')
  //   }
  // }
  useEffect(() => {
    if (localStorage.getItem('bidToken')) {
      getgroupList()

    }
  }, [])
  useEffect(() => {
    if (localStorage.getItem('bidToken')) {
      getgroupList()

    }
  }, [getsortinggroupids])

  useEffect(() => {
    getgroupList()
    setgroupid(getgroupinfoupdate)
    propdata(getgroupinfoupdate)
  }, [getgroupinfoupdate])
  // useEffect(() => {
  //   Setgroupinfoupdate()
  // }, [getgroupinfoupdate])


  const handleUserClick = async (userId) => {
    setgroupid(userId)
    settemraryuserids(prevUserIds => {
      const updatedUserIds = [...prevUserIds, userId].slice(-2);
      return updatedUserIds;
    });
    propdata(userId)
  }

  const filteredgroupList = Getgropelist?.filter((item) =>
    item.chats_group_member?.chats_mstr_group?.group_name?.toLowerCase().includes(searchname?.toLowerCase())
  );

  const filtereduserList = getuserlistData?.filter((item) =>
    item?.user?.userfullname?.toLowerCase().includes(searchname?.toLowerCase())
  );

  function handleImageError(e) {
    e.target.src = profile; // Replace 'placeholder.png' with the URL of your placeholder image
  }
  function valueExistsInArrays_online(array, value) {
    if (!array || !array.data || array.data.length === 0) {
      return false; // Return false when array.data is empty or undefined
    }
    const data_match = value;
    const isIdMatched = array.data.includes(data_match);
    return isIdMatched;
  }
  // const sortedUserList = filtereduserList.sort((a, b) => {
  //   console.log("Sorting:", a.user_id, b.user_id);
  //   const indexA = getsortinguserids.indexOf(a.user_id);
  //   const indexB = getsortinguserids.indexOf(b.user_id);
  //   if (indexA === -1 || indexB === -1) {
  //     return 0;
  //   }

  //   return indexA - indexB;
  // });
  // const filteredUsersToShow = filtereduserList.filter(item => getsortinguserids.includes(item.user_id));
  // const sortedUserList = getsortinguserids?.map(userId => {
  //   const matchedUser = filtereduserList.find(item => item.user_id === userId?.to_user_id);
  //   return matchedUser;
  // });
  // console.log(sortedUserList, '--------------------------')
  return (


    <div className="userList_wrapper position-relative h-50 flex-grow-1">
      <Tabs
        className="user-type-tab"
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: 'Users',
            children:
              (filtereduserList.length > 0) ?
                // username: item.userfullname
                filtereduserList.map((item, index) => (

                  // const isIdMatched = onlineUsers.data.includes(item.user_id);
                  // 

                  <div key={index} className="userItem" onClick={() => handleUserClick({ user_id: item.user_id, username: item?.user?.userfullname, file_name: (item?.user?.profileimg) ? item?.user?.profileimg : "", file_path: (item?.user?.profileimg_path) ? item?.user?.profileimg_path : '' })}>
                    {(item?.user?.profileimg && item?.user?.profileimg_path) ?

                      <div className="relative" style={{ position: "relative" }}>
                        <img src={`${docurlchat}${item?.user?.profileimg_path}/${item?.user?.profileimg}`} alt='' onError={(e) => handleImageError(e)} />
                        <div className={(valueExistsInArrays_online(onlineUsers, `${item.user_id}`)) ? "pointOnline" : ""} />
                      </div>
                      :
                      // <Avatar size="large" />
                      <div className="relative" style={{ position: "relative" }}>
                        <Avatar size="large" icon={<UserOutlined />} alt='' />
                        <div className={(valueExistsInArrays_online(onlineUsers, `${item.user_id}`)) ? "pointOnline" : ""} />
                      </div>

                    }
                    <div className="det">
                      <div className="name">
                        <span>{item?.user?.userfullname}</span>
                        {/* <div className="date_time">9:25 pm</div> */}
                      </div>
                      <div className="last_msg_tym">
                        <div className="lastMesg">   {
                          (item?.last_message?.message) ? (
                            <p>{item?.last_message?.message}</p>
                          ) : item?.last_message?.file_name ? (
                            <p>files</p>
                          ) : ""
                        }</div>
                        {/* <div className="time">{item.user_id}</div> */}
                        {/* /// <div className="time">{(valueExistsInArrays_count_unreadmassege(item.user_id)) ? valueExistsInArrays_count_unreadmassege(item.user_id) : ""}</div> */}
                        <div className={`${((item.chat_unread_count !== 0) && ((item.user_id !== select_user_id))) ? 'time' : ''}`}>
                          {((item.user_id == select_user_id) && (recipient.length > 0)) ? setRecipient([]) : ""}
                          {((recipient[0]?.to_user_id == userData?.data?.id) && (item.user_id !== select_user_id)) ? (
                            (recipient[0]?.from_user_id == item.user_id) ?
                              <>{item.chat_unread_count += 1, setRecipient([])}</>
                              : ""
                          ) : ((item.user_id == select_user_id)) ? '' : item.chat_unread_count != 0 && item.chat_unread_count
                            // ) : item.chat_unread_count != 0 ? item.chat_unread_count : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  <div className="px-3">No records found</div>
                )
            ,
          },
          {
            key: '2',
            label: "Groups",
            children: <>
              {(filteredgroupList?.length > 0) ? (
                filteredgroupList.map((item, index) => (
                  <div key={index}
                    className={`userItem${index === filteredgroupList.length - 1 ? ' lastItem' : ''}`}
                    onClick={() => handleUserClick({ group_id: item.group_id, group_name: item.chats_group_member?.chats_mstr_group?.group_name, file: item.chats_group_member?.chats_mstr_group?.group_profile })}>
                    {(item.chats_group_member?.chats_mstr_group?.group_profile) ?
                      <img src={`${docurlchat}${item.chats_group_member?.chats_mstr_group?.group_profile}`} alt='' onError={(e) => handleImageError(e)} />
                      :
                      <Avatar size="large" icon={<UserOutlined />} alt='' />
                    }


                    <div className="det">
                      <div className="name">
                        <span>{item.chats_group_member?.chats_mstr_group?.group_name}
                        </span>
                        <div className="last_msg_tym">

                          {/* <div className="time"> */}
                          <div className={`${((item.chat_unread_count !== 0) && ((item.group_id !== getgroupid.group_id))) ? 'time' : ''}`}>
                            {item.chat_unread_count != 0 && item.chat_unread_count}
                            {/* {((getgroupmsg[0]?.from_user_id == bidgridData?.data?.id) && (getgroupmsg.length > 0)) ? setgroupmsg([]) : ""}
                          {((getgroupmsg[0]?.group_id == item.group_id) && (getgroupmsg[0]?.from_user_id !== bidgridData?.data?.id)) ? (
                            <>{
                              item.chat_unread_count += 1,
                              setgroupmsg([])}</>

                          ) : ((item.group_id == getgroupid?.group_id)) ? item.chat_unread_count = 0 : item.chat_unread_count
                          } */}
                          </div>
                          {/* {
                          (item?.last_message?.message) ? (
                            <p>{item?.last_message?.message}</p>
                          ) : item?.last_message?.file_name ? (
                            <p>files</p>
                          ) : ""
                        } */}
                          {/*<div className="date_time">9:25 pm</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="px-3">No records found</div>
              )}
            </>
          }
        ]}
      />

    </div >
  );
};
export default UserList
